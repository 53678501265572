export const CREATE_CATEGORY_REQUEST = "CREATE_CATEGORY_REQUEST";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

export const ALL_CATEGORY_REQUEST = "ALL_CATEGORY_REQUEST";
export const ALL_CATEGORY_SUCCESS = "ALL_CATEGORY_SUCCESS";
export const ALL_CATEGORY_FAIL = "ALL_CATEGORY_FAIL";

export const CATEGORY_DETAIL_REQUEST = "CATEGORY_DETAIL_REQUEST";
export const CATEGORY_DETAIL_SUCCESS = "CATEGORY_DETAIL_SUCCESS";
export const CATEGORY_DETAIL_FAIL = "CATEGORY_DETAIL_FAIL";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";
export const CLEAR_CATEGORY_DELETE_STATE = "CLEAR_CATEGORY_DELETE_STATE";

export const CLEAR_CATEGORY_ERRORS = "CLEAR_CATEGORY_ERRORS";
export const CLEAR_CATEGORY_STATE = "CLEAR_CATEGORY_STATE";


