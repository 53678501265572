import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../styles/batchCreate.scss";
import CustomInput from "../reusable/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import ManualButton from "../reusable/ManualButton";
import ButtonLoader from "../reusable/ButtonLoader";
import Loader from "../reusable/Loader";
import {
  batchClearErrors,
  batchClearState,
  getBatchDetails,
  updateBatch,
} from "../../redux/action/batchAction";
const EditBatch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    title: "",
    time: "",
    studentLimit: "",
  });

  const { loading, batchDetails, error } = useSelector(
    (state) => state.getBatchDetails
  );

  useEffect(() => {
    dispatch(getBatchDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (error) {
      dispatch(batchClearErrors());
      navigate("/admin/batches");
    }
    if (batchDetails) {
      setFormData({
        title: batchDetails?.batch?.title || "",
        time: batchDetails?.batch?.time || "",
        studentLimit: batchDetails?.batch?.studentLimit || "",
      });
    }
  }, [dispatch, error, batchDetails, navigate]);

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { title, time, studentLimit } = formData;
  //   DESTRUCTURE ALL DATA ENDS

  //   CREATE COURSE ACTION
  const handleSubmit = () => {
    dispatch(updateBatch(id, title, time, studentLimit));
  };

  const {
    batchData,
    error: updateError,
    loading: updateLoading,
  } = useSelector((state) => state.batchUpdate);

  useEffect(() => {
    if (updateError) {
      showNotification("error", updateError);
      dispatch(batchClearErrors());
    }
    if (batchData?.success) {
      showNotification("success", batchData?.message);
      navigate("/admin/batches");
      dispatch(batchClearState());
    }
  }, [
    updateError,
    batchData?.success,
    batchData?.message,
    showNotification,
    navigate,
    dispatch,
  ]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="create_batch">
              <div className="title">
                <button
                  className="create_btn"
                  onClick={() => navigate("/admin/batches")}
                >
                  <IoMdArrowRoundBack />
                </button>
                <h2>Edit Batch</h2>
              </div>
              <div className="course_form">
                <CustomInput
                  label="Batch Title"
                  impStar="*"
                  placeholder="Batch Title"
                  type="text"
                  onChange={handleInput}
                  value={formData.title}
                  name="title"
                />
                <CustomInput
                  label="Batch Time"
                  impStar="*"
                  placeholder="Batch Time"
                  type="time"
                  onChange={handleInput}
                  value={formData.time}
                  name="time"
                />
                <CustomInput
                  label="Batch Seats"
                  impStar="*"
                  placeholder="Batch Seats"
                  type="number"
                  onChange={handleInput}
                  value={formData.studentLimit}
                  name="studentLimit"
                />
              </div>
              <div className="submit_form">
                <ManualButton
                  className="create"
                  title={updateLoading ? <ButtonLoader /> : "Update"}
                  onClick={handleSubmit}
                  width={"110px"}
                />
              </div>
            </div>
          </ProfileLayout>
        </Layout>
      )}
    </Fragment>
  );
};

export default EditBatch;
