import { axiosInstance } from "../../utils/axiosInstance";
// import { CLEAR_UPDATE_STATE } from "../constant/adminConstant";
import {
  VIEW_CERTIFICATE_FAIL,
  VIEW_CERTIFICATE_REQUEST,
  VIEW_CERTIFICATE_SUCCESS,
  CLEAR_DOWNLOAD_ERROR,
  CLEAR_DOWNLOAD_STATE,
  CREATE_CERTIFICATE_REQUEST,
  CREATE_CERTIFICATE_FAIL,
  CREATE_CERTIFICATE_SUCCESS,
  // EDIT_CERTIFICATE_REQUEST,
  // EDIT_CERTIFICATE_SUCCESS,
  // EDIT_CERTIFICATE_FAIL,
  GET_ALL_CERTIFICATE_REQUEST,
  GET_ALL_CERTIFICATE_SUCCESS,
  GET_ALL_CERTIFICATE_FAIL,
  UPDATE_CERTIFICATE_REQUEST,
  UPDATE_CERTIFICATE_SUCCESS,
  UPDATE_CERTIFICATE_FAIL,
  CLEAR_UPDATE_STATE
} from "../constant/certificateConstant";

// CREATE CERTIFICATE OF USER
export const createCertifcate =
  (rollNo, courseId, grade, startDate, endDate, duration,issueDate) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_CERTIFICATE_REQUEST,
      });
      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };
      const { data } = await axiosInstance.post(
        "/create-result",
        {
          rollNo,
          courseId,
          grade,
          startDate,
          endDate,
          duration,
          issueDate
        },
        config
      );
      dispatch({
        type: CREATE_CERTIFICATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_CERTIFICATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE RESULT DETAILS
// export const updateResult =
//   (id, rollNo, courseId, grade, startDate, endDate, duration) =>
//   async (dispatch) => {
//     try {
//       dispatch({
//         type: EDIT_CERTIFICATE_REQUEST,
//       });
//       const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
//         ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
//         : "";

//       const config = {
//         headers: {
//           "content-type": "multipart/form-data",
//           Authorization: `Bearer ${sessionStorageToken?.token}`,
//         },
//       };

//       const { data } = await axiosInstance.put(
//         `/course/${id}`,
//         {
//           id,
//           rollNo,
//           courseId,
//           grade,
//           startDate,
//           endDate,
//           duration,
//         },
//         config
//       );
//       dispatch({
//         type: EDIT_CERTIFICATE_SUCCESS,
//         payload: data,
//       });
//     } catch (error) {
//       dispatch({
//         type: EDIT_CERTIFICATE_FAIL,
//         payload: error.response.data.message,
//       });
//     }
//   };

// GET ALL RESULT LIST
export const getAllResult = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_CERTIFICATE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/result-list", config);

    dispatch({
      type: GET_ALL_CERTIFICATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_CERTIFICATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER CERTIFICATE DETAILS
export const viewCertificate = (roll_no, course_id) => async (dispatch) => {
  try {
    dispatch({
      type: VIEW_CERTIFICATE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/find-result/${roll_no}/${course_id}`,
      config
    );

    dispatch({
      type: VIEW_CERTIFICATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VIEW_CERTIFICATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateResult = (id,isActive) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CERTIFICATE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(
      `/result-status/${id}`,
      isActive,
      config
    );

    dispatch({
      type: UPDATE_CERTIFICATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CERTIFICATE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearDownloadErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_DOWNLOAD_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearDownloadState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DOWNLOAD_STATE });
};


export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};
