import React, { Fragment, useEffect, useState } from "react";
import { FaEdit, FaUserAlt } from "react-icons/fa";
import { MdOutlineMail, MdOutlineSmartphone, MdPinDrop } from "react-icons/md";
import { LiaAddressBookSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../context/notificationContext";
import { useAuth } from "../context/userContext";
import Loader from "../components/reusable/Loader";
import CustomInput from "../components/reusable/CustomInput";
import Layout from "../components/layouts/Layout";
import ProfileLayout from "../components/layouts/SidebarLayout";
import ManualButton from "../components/reusable/ManualButton";
import ButtonLoader from "../components/reusable/ButtonLoader";
import "../styles/profile.scss";
import { adminProfile, clearAdminErrors, clearUpdateState, updateUserProfile } from "../redux/action/adminAction";
// import { IoMdAdd } from "react-icons/io";
const MyProfile = () => {
  const [auth] = useAuth();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  const { loading } = useSelector((state) => state.profile);

  const {
    loading: updateLoading,
    userInfo,
    error: updateError,
  } = useSelector((state) => state.updateProfile);

  
  // GET sessionStorage DATA
  const localStorageData = sessionStorage.getItem("niotamAdminToken")
    ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
    : "";

  const [editable, setEditable] = useState(false);

  const [profileData, setProfileData] = useState({
    fullName: auth?.user?.fullName,
    email: auth?.user?.email,
    phone: auth?.user?.phone,
    pincode: auth?.user?.pincode,
    city: auth?.user?.city,
    avatar: auth?.user?.avatar,
    state: auth?.user?.state,
    village: auth?.user?.village,
    gender: auth?.user?.gender,
  });

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  // DESTRUCTURE ALL FORM INPUT
  const {
    fullName,
    email,
    phone,
    gender,
    city,
    state,
    village,
    pincode,
    avatar,
  } = profileData;

// UPDATE PROFILE FUNCTION STARTS
  const handleUpdateProfile = () => {
    dispatch(
      updateUserProfile(
        fullName,
        email,
        phone,
        gender,
        avatar,
        state,
        city,
        village,
        pincode
      )
    );
  };

  // UPDATE PROFILE FUNCTION ENDS

  // HANDLE ERROR IN UPDATE PROFILE START
  useEffect(() => {
    if (userInfo?.success) {
      setEditable(false);
      showNotification("success", userInfo?.message);
      dispatch(clearUpdateState());
      dispatch(adminProfile());
    }
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearAdminErrors());
    }
  }, [
    dispatch,
    localStorageData?.token,
    userInfo?.success,
    userInfo?.message,
    updateError,
    showNotification,
  ]);

   // HANDLE ERROR IN UPDATE PROFILE ENDS

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="container">
              <div className="profile_wrapper">
                <div className="details_section">
                  <div className="title">
                    <h2>Personal Details</h2>
                    <button
                      className={`edit_btn ${editable ? "active" : ""}`}
                      onClick={() => setEditable(!editable)}
                    >
                      <FaEdit />
                      Edit
                      {/* <IoMdAdd /> */}
                    </button>
                  </div>
                  <form>
                    <CustomInput
                      label="Full Name"
                      placeholder="Your Full Name"
                      type="text"
                      onChange={handleInputChange}
                      value={
                        editable ? profileData.fullName : auth?.user?.fullName
                      }
                      name="fullName"
                      readOnly={editable ? false : true}
                      icon={<FaUserAlt />}
                      borderColor={editable ? "#02298A" : ""}
                    />
                    <CustomInput
                      label="Email"
                      placeholder="Your Email"
                      type="email"
                      onChange={handleInputChange}
                      value={editable ? profileData.email : auth?.user?.email}
                      name="email"
                      readOnly={editable ? false : true}
                      icon={<MdOutlineMail />}
                      borderColor={editable ? "#02298A" : ""}
                    />
                    <CustomInput
                      label="Whatsapp No."
                      type="number"
                      placeholder="Your Whatsapp No."
                      name="phone"
                      readOnly={editable ? false : true}
                      value={editable ? profileData.phone : auth?.user?.phone}
                      onChange={handleInputChange}
                      icon={<MdOutlineSmartphone />}
                      borderColor={editable ? "#02298A" : ""}
                    />
                    <CustomInput
                      label="City"
                      type="text"
                      placeholder="Your City"
                      name="city"
                      readOnly={editable ? false : true}
                      value={editable ? profileData.city : auth?.user?.city}
                      onChange={handleInputChange}
                      icon={<MdPinDrop />}
                      borderColor={editable ? "#02298A" : ""}
                    />
                    <CustomInput
                      label="State"
                      type="text"
                      placeholder="Your State"
                      name="state"
                      readOnly={editable ? false : true}
                      value={editable ? profileData.state : auth?.user?.state}
                      onChange={handleInputChange}
                      icon={<LiaAddressBookSolid />}
                      borderColor={editable ? "#02298A" : ""}
                    />
                  </form>
                  {editable && (
                    <div className="submit_form">
                      <ManualButton
                        title={
                          updateLoading ? <ButtonLoader /> : "Update Profile"
                        }
                        onClick={handleUpdateProfile}
                        width={"160px"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ProfileLayout>
        </Layout>
      )}
    </Fragment>
  );
};

export default MyProfile;
