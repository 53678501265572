import { ALL_CATEGORY_FAIL, ALL_CATEGORY_REQUEST, ALL_CATEGORY_SUCCESS, CATEGORY_DETAIL_FAIL, CATEGORY_DETAIL_REQUEST, CATEGORY_DETAIL_SUCCESS, CLEAR_CATEGORY_DELETE_STATE, CLEAR_CATEGORY_ERRORS, CLEAR_CATEGORY_STATE, CREATE_CATEGORY_FAIL, CREATE_CATEGORY_REQUEST, CREATE_CATEGORY_SUCCESS, DELETE_CATEGORY_FAIL, DELETE_CATEGORY_REQUEST, DELETE_CATEGORY_SUCCESS, UPDATE_CATEGORY_FAIL, UPDATE_CATEGORY_REQUEST, UPDATE_CATEGORY_SUCCESS } from "../constant/categoryConstant";

export const categoryCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case CREATE_CATEGORY_REQUEST:
        return {
          loading: true,
          categoryData: {},
        };
      case CREATE_CATEGORY_SUCCESS:
        return {
          loading: false,
          categoryData: action.payload,
        };
      case CREATE_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_CATEGORY_ERRORS:
        return {
          ...state,
          error: null,
        };
      case CLEAR_CATEGORY_STATE:
        return {
          loading: false,
          categoryData: {},
        };
  
      default:
        return state;
    }
  };
  
  // UPDATE COURSE REDUCER
  export const categoryUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_CATEGORY_REQUEST:
        return {
          loading: true,
          categoryData: {},
        };
      case UPDATE_CATEGORY_SUCCESS:
        return {
          loading: false,
          categoryData: action.payload,
        };
      case UPDATE_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_CATEGORY_ERRORS:
        return {
          ...state,
          error: null,
        };
      case CLEAR_CATEGORY_STATE:
        return {
          loading: false,
          categoryData: {},
        };
  
      default:
        return state;
    }
  };
  
  // GET ALL COURSES REDUCER
  export const getAllCategoryReducer = (state = {}, action) => {
    switch (action.type) {
      case ALL_CATEGORY_REQUEST:
        return {
          loading: true,
          categoryList: {},
        };
      case ALL_CATEGORY_SUCCESS:
        return {
          loading: false,
          categoryList: action.payload,
        };
      case ALL_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_CATEGORY_ERRORS:
        return {
          categoryList: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  // GET COURSE DETAILS REDUCER
  export const getCategoryDetailsReducer = (state = {}, action) => {
    switch (action.type) {
      case CATEGORY_DETAIL_REQUEST:
        return {
          loading: true,
          categoryDetails: {},
        };
      case CATEGORY_DETAIL_SUCCESS:
        return {
          loading: false,
          categoryDetails: action.payload,
        };
      case CATEGORY_DETAIL_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_CATEGORY_ERRORS:
        return {
          categoryDetails: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  // DELETE COURSE DETAILS REDUCER
  export const deleteCategoryReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_CATEGORY_REQUEST:
        return {
          loading: true,
          category: {},
        };
      case DELETE_CATEGORY_SUCCESS:
        return {
          loading: false,
          category: action.payload,
        };
      case DELETE_CATEGORY_FAIL:
        return {
          loading: false,
          error: action.payload,
        };
      case CLEAR_CATEGORY_ERRORS:
        return {
          ...state,
          loading: false,
          error: null,
        };
      case CLEAR_CATEGORY_DELETE_STATE:
        return {
          category: {},
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };