import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../styles/CategoryCreate.scss";
import CustomInput from "../reusable/CustomInput";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
// import { BsCurrencyRupee } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import ManualButton from "../reusable/ManualButton";
import ButtonLoader from "../reusable/ButtonLoader";
import {
  categoryClearErrors,
  categoryClearState,
  createCategory,
} from "../../redux/action/categoryAction";
const CategoryCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    category: "",
  });
  const [subjects, setSubjects] = useState();
  const [subjectList, setSubjectList] = useState([]);

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //   DESTRUCTURE ALL DATA
  const { category } = formData;

  // HANDLE FEATURE ADDS START
  const handleAddCategory = () => {
    if (!subjects) {
      showNotification("error", "Please add a subjects");
    } else if (subjectList?.length >= 6) {
      showNotification("error", "You can add maximum 6 subjects");
    } else {
      setSubjectList([...subjectList, subjects]);
      setSubjects("");
    }
  };
  // HANDLE REMOVE FEATURE
  const handleRemoveFeature = (index) => {
    const updatedFeatureList = subjectList.filter((_, i) => i !== index);
    // Update the state with the new array
    setSubjectList(updatedFeatureList);
  };

  //   CREATE COURSE ACTION
  const handleSubmit = (e) => {
    dispatch(createCategory(category, subjectList));
  };

  const {
    categoryData,
    error: createError,
    loading: createLoading,
  } = useSelector((state) => state.categoryCreate);

  useEffect(() => {
    if (createError) {
      showNotification("error", createError);
      dispatch(categoryClearErrors());
    }
    if (categoryData?.success) {
      showNotification("success", categoryData?.message);
      navigate("/admin/category");
      dispatch(categoryClearState());
    }
  }, [
    createError,
    categoryData?.success,
    categoryData?.message,
    showNotification,
    navigate,
    dispatch,
  ]);
  return (
    <Fragment>
      <Layout>
        <ProfileLayout>
          <div className="create_category">
            <div className="title">
              <button
                className="create_btn"
                onClick={() => navigate("/admin/category")}
              >
                <IoMdArrowRoundBack />
              </button>
              <h2>Create Category</h2>
            </div>
            <div className="course_form">
              <CustomInput
                label="Category Name"
                impStar="*"
                placeholder="Category Title"
                type={"text"}
                onChange={handleInput}
                value={formData.category}
                name={"category"}
                icon={""}
              />

              <div className="sc-irTswW izZXSN myInput">
                <label>
                  Category Subjects
                  <abbr className="impStar">*</abbr>
                </label>
                <div className="input_icon courseFeatures">
                  <input
                    className="input_text"
                    type="text"
                    placeholder="etc..."
                    name="subjects"
                    value={subjects}
                    onChange={(e) => setSubjects(e.target.value)}
                  />
                  <button title="Add More Subjects" onClick={handleAddCategory}>
                    <FaPlus />
                  </button>
                </div>
                {subjectList && subjectList?.length > 0 && (
                  <div className="feature_list">
                    <ul>
                      {subjectList.map((ele, i) => (
                        <li key={i}>
                          <div>
                            <p>{ele}</p>
                            <span onClick={() => handleRemoveFeature(i)}>
                              <RxCross2 />
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="submit_form">
              <ManualButton
                className="create"
                title={createLoading ? <ButtonLoader /> : "Create"}
                onClick={handleSubmit}
                width={"110px"}
              />
            </div>
          </div>
        </ProfileLayout>
      </Layout>
    </Fragment>
  );
};

export default CategoryCreate;
