export const indianStates = [
    { id: 1, value: 'andhraPradesh', label: 'ANDHRA PRADESH' },
    { id: 2, value: 'arunachalPradesh', label: 'ARUNACHAL PRADESH' },
    { id: 3, value: 'assam', label: 'ASSAM' },
    { id: 4, value: 'bihar', label: 'BIHAR' },
    { id: 5, value: 'chhattisgarh', label: 'CHHATTISGARH' },
    { id: 6, value: 'goa', label: 'GOA' },
    { id: 7, value: 'gujarat', label: 'GUJARAT' },
    { id: 8, value: 'haryana', label: 'HARYANA' },
    { id: 9, value: 'himachalPradesh', label: 'HIMACHAL PRADESH' },
    { id: 10, value: 'jammuAndKashmir', label: 'JAMMU AND KASHMIR' },
    { id: 11, value: 'jharkhand', label: 'JHARKHAND' },
    { id: 12, value: 'karnataka', label: 'KARNATAKA' },
    { id: 13, value: 'kerala', label: 'KERALA' },
    { id: 14, value: 'madhyaPradesh', label: 'MADHYA PRADESH' },
    { id: 15, value: 'maharashtra', label: 'MAHARASHTRA' },
    { id: 16, value: 'manipur', label: 'MANIPUR' },
    { id: 17, value: 'meghalaya', label: 'MEGHALAYA' },
    { id: 18, value: 'mizoram', label: 'MIZORAM' },
    { id: 19, value: 'nagaland', label: 'NAGALAND' },
    { id: 20, value: 'odisha', label: 'ODISHA' },
    { id: 21, value: 'punjab', label: 'PUNJAB' },
    { id: 22, value: 'rajasthan', label: 'RAJASTHAN' },
    { id: 23, value: 'sikkim', label: 'SIKKIM' },
    { id: 24, value: 'tamilNadu', label: 'TAMIL NADU' },
    { id: 25, value: 'telangana', label: 'TELANGANA' },
    { id: 26, value: 'tripura', label: 'TRIPURA' },
    { id: 27, value: 'uttarPradesh', label: 'UTTAR PRADESH' },
    { id: 28, value: 'uttarakhand', label: 'UTTARAKHAND' },
    { id: 29, value: 'westBengal', label: 'WEST BENGAL' },
    { id: 30, value: 'andamanAndNicobarIslands', label: 'ANDAMAN AND NICOBAR ISLANDS' },
    { id: 31, value: 'chandigarh', label: 'CHANDIGARH' },
    { id: 32, value: 'dadraAndNagarHaveliAndDamanAndDiu', label: 'DADRA AND NAGAR HAVELI AND DAMAN AND DIU' },
    { id: 33, value: 'lakshadweep', label: 'LAKSHADWEEP' },
    { id: 34, value: 'delhi', label: 'DELHI' },
    { id: 35, value: 'puducherry', label: 'PUDUCHERRY' },
  ];
  