import {
  CLEAR_MARKSHEET_DOWNLOAD_ERROR,
  CLEAR_MARKSHEET_DOWNLOAD_STATE,
  CLEAR_MARKSHEET_UPDATE_STATE,
  CREATE_MARKSHEET_FAIL,
  CREATE_MARKSHEET_REQUEST,
  CREATE_MARKSHEET_SUCCESS,
  GET_ALL_MARKSHEET_FAIL,
  GET_ALL_MARKSHEET_REQUEST,
  GET_ALL_MARKSHEET_SUCCESS,
  UPDATE_MARKSHEET_FAIL,
  UPDATE_MARKSHEET_REQUEST,
  UPDATE_MARKSHEET_SUCCESS,
  VIEW_MARKSHEET_FAIL,
  VIEW_MARKSHEET_REQUEST,
  VIEW_MARKSHEET_SUCCESS,
} from "../constant/markSheetConstant";

// RESULT CREATE REDUCER
export const markSheetCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_MARKSHEET_REQUEST:
      return {
        loading: true,
        markSheetData: {},
      };
    case CREATE_MARKSHEET_SUCCESS:
      return {
        loading: false,
        markSheetData: action.payload,
      };
    case CREATE_MARKSHEET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_MARKSHEET_DOWNLOAD_ERROR:
      return {
        ...state,
        error: null,
      };
    case CLEAR_MARKSHEET_DOWNLOAD_STATE:
      return {
        loading: false,
        markSheetData: {},
      };

    default:
      return state;
  }
};


// GET ALL RESULT LIST
export const getAllMarksheetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_MARKSHEET_REQUEST:
      return {
        loading: true,
        markSheetList: {},
      };
    case GET_ALL_MARKSHEET_SUCCESS:
      return {
        loading: false,
        markSheetList: action.payload,
      };
    case GET_ALL_MARKSHEET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_MARKSHEET_DOWNLOAD_ERROR:
      return {
        markSheetList: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET USER CERTIFICATE REDUCER
export const findMarksheetReducer = (state = {}, action) => {
  switch (action.type) {
    case VIEW_MARKSHEET_REQUEST:
      return {
        loading: true,
        certificateInfo: {},
      };
    case VIEW_MARKSHEET_SUCCESS:
      return {
        loading: false,
        certificateInfo: action.payload,
      };
    case VIEW_MARKSHEET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_MARKSHEET_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_MARKSHEET_DOWNLOAD_STATE:
      return {
        loading: false,
        userInfo: null,
      };

    default:
      return state;
  }
};



// update user profile
export const updateMarksheetReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MARKSHEET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_MARKSHEET_SUCCESS:
      return {
        loading: false,
        marksheetInfo: action.payload,
      };
    case UPDATE_MARKSHEET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_MARKSHEET_DOWNLOAD_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_MARKSHEET_UPDATE_STATE:
      return {
        loading: false,
        marksheetInfo: null,
      };

    default:
      return state;
  }
};
