// DOWNLOAD MARKSHEET CONSTANTS
export const CREATE_MARKSHEET_REQUEST = "CREATE_MARKSHEET_REQUEST";
export const CREATE_MARKSHEET_SUCCESS = "CREATE_MARKSHEET_SUCCESS";
export const CREATE_MARKSHEET_FAIL = "CREATE_MARKSHEET_FAIL";

export const UPDATE_MARKSHEET_REQUEST = "UPDATE_MARKSHEET_REQUEST";
export const UPDATE_MARKSHEET_SUCCESS = "UPDATE_MARKSHEET_SUCCESS";
export const UPDATE_MARKSHEET_FAIL = "UPDATE_MARKSHEET_FAIL";

export const VIEW_MARKSHEET_REQUEST = "VIEW_MARKSHEET_REQUEST";
export const VIEW_MARKSHEET_SUCCESS = "VIEW_MARKSHEET_SUCCESS";
export const VIEW_MARKSHEET_FAIL = "VIEW_MARKSHEET_FAIL";

export const GET_ALL_MARKSHEET_REQUEST = "GET_ALL_MARKSHEET_REQUEST";
export const GET_ALL_MARKSHEET_SUCCESS = "GET_ALL_MARKSHEET_SUCCESS";
export const GET_ALL_MARKSHEET_FAIL = "GET_ALL_MARKSHEET_FAIL";

export const CLEAR_MARKSHEET_DOWNLOAD_ERROR = "CLEAR_MARKSHEET_DOWNLOAD_ERROR";
export const CLEAR_MARKSHEET_DOWNLOAD_STATE = "CLEAR_MARKSHEET_DOWNLOAD_STATE";
export const CLEAR_MARKSHEET_UPDATE_STATE = "CLEAR_MARKSHEET_UPDATE_STATE";