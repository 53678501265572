import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import NoDataCard from "../reusable/NoDataCard";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/batchList.scss";
import Loader from "../reusable/Loader";
// import moment from "moment/moment";
import PopupPrompt from "../reusable/PopupPrompt";

// Import Material-UI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  InputBase,
} from "@material-ui/core";
import {
  batchClearErrors,
  clearBatchDeleteState,
  deleteBatch,
  getAllBatchist,
} from "../../redux/action/batchAction";

const BatchList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // SHOW POPUP MSG STATE
  const [popupMsg, setPopupMsg] = useState({});

  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  // PAGINATION FUNCTION ENDS

  const {
    loading,
    error,
    batchList: data,
  } = useSelector((state) => state.getAllBatch);

  useEffect(() => {
    dispatch(getAllBatchist());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(batchClearErrors());
    }
  }, [dispatch, error, showNotification]);

  // DELETE COUIRSE STATE GET FROM REDUCER
  const {
    loading: batchDeleteLoading,
    batch,
    error: batchDeleteError,
  } = useSelector((state) => state.deleteBatch);

  // HANLDE DELETE COURSE
  const handleDelete = (id) => {
    dispatch(deleteBatch(id));
  };

  useEffect(() => {
    if (batchDeleteError) {
      showNotification("error", batchDeleteError);
    }
    if (batch?.success) {
      showNotification("success", batch?.message);
      dispatch(getAllBatchist());
      setPopupMsg(false);
      dispatch(clearBatchDeleteState());
    }
  }, [
    batch?.success,
    batchDeleteError,
    dispatch,
    showNotification,
    batch?.message,
  ]);

  const handleViewDetails = (batchId) => {
    navigate(`/admin/update-batch/${batchId}`);
  };

  const createCategory = () => {
    navigate("/admin/create-batch");
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="batch_list">
              <div className="title">
                <h2>All Batches</h2>
                <button className="create_btn" onClick={() => createCategory()}>
                  <FaPlus />
                </button>
              </div>
              {data?.batches?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>Batch Name</b>
                          </TableCell>
                          <TableCell>
                            <b>Batch Time</b>
                          </TableCell>
                          <TableCell>
                            <b>No. Of Seats</b>
                          </TableCell>
                          <TableCell>
                            <b>Available Seats</b>
                          </TableCell>
                          <TableCell>
                            <b>Status</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.batches
                          ?.filter((course) =>
                            course.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.title}</TableCell>
                              <TableCell>{ele?.time}</TableCell>
                              <TableCell>{ele?.studentLimit}</TableCell>
                              <TableCell>{ele?.admittedStudents}</TableCell>
                              <TableCell className="txt_center">
                                <span
                                  className={`${
                                    ele?.isActive ? "green" : "red"
                                  }`}
                                >
                                  {ele?.isActive ? "Active" : "In Active"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <div className="button_container">
                                  <Button
                                    className="edit_btn"
                                    onClick={() => handleViewDetails(ele?._id)}
                                  >
                                    <AiFillEye />
                                  </Button>
                                  <Button
                                    className="delete_btn"
                                    onClick={() =>
                                      setPopupMsg({ show: true, id: ele._id })
                                    }
                                  >
                                    <MdDelete />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.batches?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no batches added yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
        </Layout>
      )}

      {/* DELETE COURSE POPUP START */}
      {popupMsg.show && (
        <PopupPrompt
          confirmBtnTxt="Confirm"
          cancelBtnTxt="Cancel"
          title="Are you sure! You want to delete this batch."
          confirmOnclick={() => handleDelete(popupMsg?.id)}
          cancelOnclick={() => setPopupMsg(false)}
          loading={batchDeleteLoading}
        />
      )}
      {/* DELETE COURSE POPUP ENDS */}
    </Fragment>
  );
};

export default BatchList;
