import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import NoDataCard from "../reusable/NoDataCard";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/categoryList.scss";
import Loader from "../reusable/Loader";
import moment from "moment/moment";
import PopupPrompt from "../reusable/PopupPrompt";

// Import Material-UI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  TableSortLabel,
  InputBase,
} from "@material-ui/core";
import {
  categoryClearErrors,
  clearCategoryDeleteState,
  deleteCategory,
  getAllCategoryList,
} from "../../redux/action/categoryAction";

const CategoryList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // SHOW POPUP MSG STATE
  const [popupMsg, setPopupMsg] = useState({});

  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  // PAGINATION FUNCTION ENDS

  const {
    loading,
    error,
    categoryList: data,
  } = useSelector((state) => state.getAllCategory);

  useEffect(() => {
    dispatch(getAllCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(categoryClearErrors());
    }
  }, [dispatch, error, showNotification]);

  // DELETE COUIRSE STATE GET FROM REDUCER
  const {
    loading: categoryDeleteLoading,
    category,
    error: categoryDeleteError,
  } = useSelector((state) => state.deleteCategory);

  // HANLDE DELETE COURSE
  const handleDelete = (id) => {
    dispatch(deleteCategory(id));
  };
  useEffect(() => {
    if (categoryDeleteError) {
      showNotification("error", categoryDeleteError);
    }
    if (category?.success) {
      showNotification("success", category?.message);
      dispatch(getAllCategoryList());
      setPopupMsg(false);
      dispatch(clearCategoryDeleteState());
    }
  }, [
    category?.success,
    categoryDeleteError,
    dispatch,
    showNotification,
    category?.message,
  ]);

  const handleViewDetails = (categoryId) => {
    navigate(`/admin/update-category/${categoryId}`);
  };

  const createCategory = () => {
    navigate("/admin/create-category");
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="category_List">
              <div className="title">
                <h2>Course Category</h2>
                <button className="create_btn" onClick={() => createCategory()}>
                  <FaPlus />
                </button>
              </div>
              {data?.category?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel>
                              <b>Category Name</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>
                              <b>Category Subjects</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <b>Created Date & Time</b>
                          </TableCell>
                          <TableCell>
                            <b>Status</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.category
                          ?.filter((course) =>
                            course.category
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.category}</TableCell>
                              <TableCell>
                                {ele?.subjects.map((subject, index) => (
                                  <span key={index}>{subject}</span>
                                ))}
                              </TableCell>
                              <TableCell>
                                {moment(ele?.createdAt).format(
                                  "MMMM-DD-YYYY [at] h:mm A"
                                )}
                              </TableCell>
                              <TableCell className="txt_center">
                                <span
                                  className={`${
                                    ele?.isActive ? "green" : "red"
                                  }`}
                                >
                                  {ele?.isActive ? "Active" : "Ended"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <div className="button_container">
                                  <Button
                                    className="edit_btn"
                                    onClick={() => handleViewDetails(ele?._id)}
                                  >
                                    <AiFillEye />
                                  </Button>
                                  <Button
                                    className="delete_btn"
                                    onClick={() =>
                                      setPopupMsg({ show: true, id: ele._id })
                                    }
                                  >
                                    <MdDelete />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.category?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no category added yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
        </Layout>
      )}

      {/* DELETE COURSE POPUP START */}
      {popupMsg.show && (
        <PopupPrompt
          confirmBtnTxt="Confirm"
          cancelBtnTxt="Cancel"
          title="Are you sure! You want to delete this Category."
          confirmOnclick={() => handleDelete(popupMsg?.id)}
          cancelOnclick={() => setPopupMsg(false)}
          loading={categoryDeleteLoading}
        />
      )}
      {/* DELETE COURSE POPUP ENDS */}
    </Fragment>
  );
};

export default CategoryList;
