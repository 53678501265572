import React, { Fragment, useEffect } from "react";
import Layout from "../components/layouts/Layout";
import { FaBookOpen, FaLayerGroup, FaUser } from "react-icons/fa";
import "../styles/home.scss";
import ProfileLayout from "../components/layouts/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { clearStatsError, getStats } from "../redux/action/statsAction";
import { useNotification } from "../context/notificationContext";
import Loader from "../components/reusable/Loader";
import { MdOutlineEventNote, MdOutlineRequestPage } from "react-icons/md";
import { BiCategory } from "react-icons/bi";
const Home = () => {
  const [, , showNotification] = useNotification();
  const dispatch = useDispatch();

  const { loading, statsInfo, error } = useSelector(
    (state) => state.adminStats
  );

  useEffect(() => {
    dispatch(getStats());
  }, [dispatch]);

  const statsList = statsInfo;

  console.log(statsList);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearStatsError());
    }
  }, [error, showNotification, dispatch]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="home_wrapper">
              <div className="card">
                <div className="count">
                  <p>{statsList?.stats?.courses}</p>
                  <span>
                    <FaBookOpen />
                  </span>
                </div>
                <div className="title">Courses</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>{statsList?.stats?.users}</p>
                  <span>
                    <FaUser />
                  </span>
                </div>
                <div className="title">Users</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>{statsList?.stats?.batch}</p>
                  <span>
                    <FaLayerGroup />
                  </span>
                </div>
                <div className="title">Batches</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>{statsList?.stats?.results}</p>
                  <span>
                    <MdOutlineEventNote />
                  </span>
                </div>
                <div className="title">Result</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>{statsList?.stats?.category}</p>
                  <span>
                    <BiCategory />
                  </span>
                </div>
                <div className="title">Category</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>{statsList?.stats?.franchiseRequest}</p>
                  <span>
                    <MdOutlineRequestPage />
                  </span>
                </div>
                <div className="title">Fracnhise Request</div>
              </div>
              <div className="card">
                <div className="count">
                  <p>{statsList?.stats?.purchases}</p>
                  <span>
                    <MdOutlineRequestPage />
                  </span>
                </div>
                <div className="title">Transactions</div>
              </div>
            </div>
          </ProfileLayout>
        </Layout>
      )}
    </Fragment>
  );
};

export default Home;
