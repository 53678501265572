import {
  USER_CLEAR_ERROR,
  USER_CLEAR_STATE,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  USER_REGISER_FAIL,
  USER_REGISER_REQUEST,
  USER_REGISER_SUCCESS,
  UPDATE_USER_BY_ID_REQUEST,
  UPDATE_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
} from "../constant/userConstant";

// FOR USER REGISTRATION
export const userRegister = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISER_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case USER_REGISER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case USER_REGISER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case USER_CLEAR_STATE:
      return {
        loading: false,
        userInfo: {},
        error: null,
      };

    default:
      return state;
  }
};

// GET ALL USER PROFILE REDUCER
export const getAllUserReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case GET_ALL_USERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// GET USER PROFILE REDUCER
export const getUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_USER_BY_ID_REQUEST:
      return {
        loading: true,
        userInfo: {},
      };
    case GET_USER_BY_ID_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case GET_USER_BY_ID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

// update user profile
export const updateUserByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_BY_ID_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
      };
    case UPDATE_USER_BY_ID_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case USER_CLEAR_STATE:
      return {
        loading: false,
        userInfo: null,
      };

    default:
      return state;
  }
};

// DELETE USER DETAILS REDUCER
export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_USER_REQUEST:
      return {
        loading: true,
        userDetails: {},
      };
    case DELETE_USER_SUCCESS:
      return {
        loading: false,
        userDetails: action.payload,
      };
    case DELETE_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case USER_CLEAR_STATE:
      return {
        userDetails: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
