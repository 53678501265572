import React, { Fragment, useEffect, useState } from "react";
import "../styles/login.scss";
import { useNotification } from "../context/notificationContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/userContext";
import { useDispatch, useSelector } from "react-redux";
import ManualButton from "../components/reusable/ManualButton";
import ButtonLoader from "../components/reusable/ButtonLoader";
import { FaUserAlt, FaLock, FaUser } from "react-icons/fa";
import CustomInput from "../components/reusable/CustomInput";
import { adminLogin, clearAdminErrors } from "../redux/action/adminAction";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth] = useAuth();
  const [, , showNotification] = useNotification();

  // LOGIN STATE AND PARAMETERS
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  // DESTRUCTURE FORM DATA
  const { email, password } = formData;

  // HANDLE INPUT CHANGE
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // SUBMIT LOGIN HANDLER
  const loginHandler = (e) => {
    e.preventDefault();
    if (email === "") {
      showNotification("error", "Please enter your email");
    } else if (password === "") {
      showNotification("error", "Password should be require !");
    }
    //  else if (!PasswordValidation(password)) {
    //   showNotification("error", "Password conatin capital letter, small letter and contain one special letter");
    // }
    else {
      dispatch(adminLogin(email, password));
    }
  };

  // GET LOGIN DATA FROM STORE
  const { loading, adminInfo, error } = useSelector((state) => state.userLogin);

  // MANAGE ERRORS AND RESPONSES
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearAdminErrors());
    }
    if (auth?.user?._id && auth?.user?.role !== 10) {
      showNotification("error", "Credential not matched , 2 attempt left");
    }
    if (auth?.user?.role === 10 && auth?.user?._id) {
      navigate("/admin/dashboard");
    }
  }, [
    dispatch,
    error,
    adminInfo?.message,
    adminInfo?.success,
    navigate,
    showNotification,
    auth?.user?._id,
    auth?.user?.role,
  ]);

  return (
    <Fragment>
      <div className="main_wrapper">
        <div className="login_card">
          <div className="icon">
            <FaUser />
          </div>

          <h4>Login As Admin</h4>
          <form>
            <CustomInput
              placeholder={"Your Email"}
              type={"email"}
              onChange={handleInput}
              value={formData.email}
              name={"email"}
              icon={<FaUserAlt />}
            />
            <CustomInput
              placeholder={"Password"}
              type={"password"}
              name={"password"}
              onChange={handleInput}
              value={formData.password}
              icon={<FaLock />}
              //   eyeIcon={showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
              //   setshowPassword={setshowPassword}
            />
            <div className="submit_button">
              <ManualButton
                className={"login_btn"}
                title={loading ? <ButtonLoader /> : "Login"}
                onClick={loginHandler}
                width={"120px"}
              />
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
