import {
  CLEAR_PROFILE_ERROR,
  CLEAR_UPDATE_STATE,
  ADMIN_CLEAR_ERROR,
  ADMIN_CLEAR_STATE,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT_REQUEST,
  ADMIN_LOGOUT_SUCCESS,
  ADMIN_PROFILE_FAIL,
  ADMIN_PROFILE_REQUEST,
  ADMIN_PROFILE_SUCCESS,
  ADMIN_UPDATE_FAIL,
  ADMIN_UPDATE_REQUEST,
  ADMIN_UPDATE_SUCCESS,
  REMOVE_AVATAR_FAIL,
  REMOVE_AVATAR_REQUEST,
  REMOVE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAIL,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
} from "../constant/adminConstant";

// ADMIN LOGIN REDUCER
export const adminLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        adminInfo: {},
      };

    case ADMIN_LOGIN_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        adminInfo: action.payload,
      };

    case ADMIN_LOGIN_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case ADMIN_CLEAR_ERROR:
      return {
        loading: false,
        isAuthenticated: false,
        adminInfo: null,
        error: null,
      };
    case ADMIN_CLEAR_STATE:
      return {
        loading: false,
        isAuthenticated: false,
        error: null,
        adminInfo: {},
      };

    case ADMIN_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_LOGOUT_SUCCESS:
      return {
        loading: false,
        isAuthenticated: false,
        adminInfo: {},
      };

    default:
      return state;
  }
};

// GET ADMIN PROFILE REDUCER
export const adminProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_PROFILE_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
        adminInfo: {},
      };
    case ADMIN_PROFILE_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        adminInfo: action.payload,
      };
    case ADMIN_PROFILE_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        error: action.payload,
      };
    case CLEAR_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: null,
      };

    case ADMIN_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_LOGOUT_SUCCESS:
      return {
        loading: false,
        isAuthenticated: false,
        adminInfo: {},
      };

    default:
      return state;
  }
};

// update ADMIN profile
export const updateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case ADMIN_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_UPDATE_SUCCESS:
      return {
        loading: false,
        adminInfo: action.payload,
      };
    case ADMIN_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        loading: false,
        adminInfo: null,
      };

    default:
      return state;
  }
};

// UPDATE ADMIN PROFILE ======
export const updateProfilePicReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AVATAR_REQUEST:
      return {
        loading: true,
        avatarInfo: {},
      };
    case UPDATE_AVATAR_SUCCESS:
      return {
        loading: false,
        avatarInfo: action.payload,
      };
    case UPDATE_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case ADMIN_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        avatarInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// REMOVE PROFILE PICTURE
export const removeProfilePic = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_AVATAR_REQUEST:
      return {
        loading: true,
        avatarInfo: {},
      };

    case REMOVE_AVATAR_SUCCESS:
      return {
        loading: false,
        avatarInfo: action.payload,
      };

    case REMOVE_AVATAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case ADMIN_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case CLEAR_UPDATE_STATE:
      return {
        avatarInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
