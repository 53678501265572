import { axiosInstance } from "../../utils/axiosInstance";
import {
  REMOVE_AVATAR_FAIL,
  REMOVE_AVATAR_REQUEST,
  REMOVE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAIL,
  UPDATE_AVATAR_REQUEST,
  UPDATE_AVATAR_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT_REQUEST,
  ADMIN_LOGOUT_SUCCESS,
  ADMIN_PROFILE_FAIL,
  ADMIN_PROFILE_REQUEST,
  ADMIN_PROFILE_SUCCESS,
  ADMIN_UPDATE_FAIL,
  ADMIN_UPDATE_REQUEST,
  ADMIN_UPDATE_SUCCESS,
  ADMIN_CLEAR_ERROR,
  ADMIN_CLEAR_STATE,
  CLEAR_UPDATE_STATE,
} from "../constant/adminConstant";

// USER LOGIN ACTION ==--==--==--==--==--==--==--
export const adminLogin = (email, password) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "content-type": "application/json",
      },
    };

    const { data } = await axiosInstance.post(
      `/login`,
      {
        email,
        password,
      },
      config
    );

    dispatch({
      type: ADMIN_LOGIN_SUCCESS,
      payload: data,
    });

    sessionStorage.setItem(
      "niotamAdminToken",
      JSON.stringify(getState().userLogin?.adminInfo)
    );
  } catch (error) {
    dispatch({
      type: ADMIN_LOGIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// USER LOGOUT
export const logout = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_LOGOUT_REQUEST,
    });

    sessionStorage.removeItem("niotamAdminToken");

    dispatch({
      type: ADMIN_LOGOUT_SUCCESS,
      payload: null,
    });
  } catch (error) {
    alert(error);
  }
};

// GET USER PROFILE DETAILS
export const adminProfile = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_PROFILE_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/profile`, config);

    dispatch({
      type: ADMIN_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE USER PROFILE
export const updateUserProfile =
  (fullName, email, phone, gender, avatar, state, city, village, pincode) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_REQUEST,
      });
      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };
      const { data } = await axiosInstance.put(
        `/update-profile`,
        {
          fullName,
          email,
          phone,
          avatar,
          state,
          city,
          village,
          pincode,
          gender,
        },
        config
      );
      dispatch({
        type: ADMIN_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ADMIN_UPDATE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE USER PROFILE PICTURE
export const updateAvatar = (image) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_AVATAR_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.put(`profile-pic`, image, config);

    dispatch({
      type: UPDATE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_AVATAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// REMOVE USER PROFILE PICTURE
export const removeAvatar = () => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_AVATAR_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`remove-pic`, config);

    dispatch({
      type: REMOVE_AVATAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_AVATAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearAdminErrors = () => async (dispatch) => {
  dispatch({ type: ADMIN_CLEAR_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearAdminState = () => async (dispatch) => {
  dispatch({ type: ADMIN_CLEAR_STATE });
};

// CLEAR STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};
