import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../styles/createResult.scss";
import CustomSelect from "../reusable/CustomSelect";
import CustomInput from "../reusable/CustomInput";
// import CustomTextarea from "../reusable/CustomTextArea";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
// import { BsCurrencyRupee } from "react-icons/bs";
// import { FaPlus } from "react-icons/fa";
// import { RxCross2 } from "react-icons/rx";
import ManualButton from "../reusable/ManualButton";
// import {
//   courseClearErrors,
//   courseClearState,
//   createCourse,
// } from "../../redux/action/courseAction";
import ButtonLoader from "../reusable/ButtonLoader";
import { clearUserErrors, getAllUsers } from "../../redux/action/userAction";
import {
  clearDownloadErrors,
  clearDownloadState,
  createCertifcate,
} from "../../redux/action/certificateAction";
import {
  courseClearErrors,
  getAllCourses,
} from "../../redux/action/courseAction";
import Loader from "../reusable/Loader";
const CreateResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    rollNo: "",
    courseId: "",
    grade: "",
    startDate: "",
    endDate: "",
    duration: "",
    issueDate: "",
  });
  const { rollNo, courseId, grade, startDate, endDate, duration, issueDate } =
    formData;

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //   GET ALL STUDENT LIST
  const {
    loading,
    error,
    userInfo: data,
  } = useSelector((state) => state.getAllUser);
  const allUsers = data?.users?.filter((itm) => itm.role === 11);
  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  //   MAKE ARRAY OF USERS FOR SELECT BOX
  let optionArray = [];

  for (let i = 0; i < allUsers?.length; i++) {
    let optionData = {
      value: allUsers[i]?.rollNo,
      label: allUsers[i]?.fullName,
    };
    optionArray.push(optionData);
  }

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearUserErrors());
    }
  }, [dispatch, error, showNotification]);

  //   GET ALL COURSES
  const {
    loading: courseLoading,
    error: courseError,
    courses,
  } = useSelector((state) => state.getCourses);
  const allCourses = courses?.courses;
  let optionArrayCourses = [];

  for (let i = 0; i < allCourses?.length; i++) {
    let optionData = {
      value: allCourses[i]?._id,
      label: allCourses[i]?.title,
    };
    optionArrayCourses.push(optionData);
  }
  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(courseClearErrors());
    }
  }, [dispatch, courseError, showNotification]);

  // CREATE RESULT
  const handleSubmit = (e) => {
    dispatch(
      createCertifcate(
        rollNo,
        courseId,
        grade,
        startDate,
        endDate,
        duration,
        issueDate
      )
    );
  };

  const {
    resultData,
    error: createError,
    loading: createLoading,
  } = useSelector((state) => state.createResult);

  //   HANDLE CREATE RESULT MESSAGES
  useEffect(() => {
    if (createError) {
      showNotification("error", createError);
      dispatch(clearDownloadErrors());
    }
    if (resultData?.success) {
      showNotification("success", resultData?.message);
      navigate("/admin/results");
      dispatch(clearDownloadState());
    }
  }, [
    createError,
    resultData?.success,
    resultData?.message,
    showNotification,
    navigate,
    dispatch,
  ]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="create_result">
              <div className="title">
                <button
                  className="create_btn"
                  onClick={() => navigate("/admin/results")}
                >
                  <IoMdArrowRoundBack />
                </button>
                <h2>Create Result</h2>
              </div>
              <div className="course_form">
                <CustomSelect
                  className="verify_input"
                  label="Select user for which you wanty to release result"
                  name="rollNo"
                  onChange={handleInput}
                  optionArray={optionArray}
                  impStar="*"
                  value={formData?.rollNo}
                  readOnly={false}
                  placeholder="Please select a user"
                />
                <CustomSelect
                  className="verify_input"
                  label="Select Course"
                  name="courseId"
                  onChange={handleInput}
                  optionArray={optionArrayCourses}
                  impStar="*"
                  value={formData?.courseId}
                  readOnly={false}
                  placeholder="Please select a course"
                  loading={courseLoading}
                />
                <CustomInput
                  label="Course grade"
                  impStar="*"
                  placeholder="Ex:- A++"
                  type={"text"}
                  onChange={handleInput}
                  value={formData.grade}
                  name={"grade"}
                  icon={""}
                />
                <CustomInput
                  label="Course Duration (In months)"
                  placeholder={"Course Duration"}
                  type={"number"}
                  impStar="*"
                  onChange={handleInput}
                  value={formData.duration}
                  name={"duration"}
                  icon={""}
                />
                <CustomInput
                  label="Course Start Date"
                  impStar="*"
                  placeholder="Course Start Date"
                  type="date"
                  onChange={handleInput}
                  value={formData.startDate}
                  name="startDate"
                  icon=""
                />
                <CustomInput
                  label="Course End Date"
                  impStar="*"
                  placeholder="Course End Date"
                  type="date"
                  onChange={handleInput}
                  value={formData.endDate}
                  name="endDate"
                  icon=""
                />
                <CustomInput
                  label="Date Of Issue"
                  impStar="*"
                  placeholder="Date Of Issue"
                  type="date"
                  onChange={handleInput}
                  value={formData.issueDate}
                  name="issueDate"
                  icon=""
                />
              </div>
              <div className="submit_form">
                <ManualButton
                  className="create"
                  title={createLoading ? <ButtonLoader /> : "Create"}
                  onClick={handleSubmit}
                  width={"110px"}
                />
              </div>
            </div>
          </ProfileLayout>
        </Layout>
      )}
    </Fragment>
  );
};

export default CreateResult;
