import {
  CLEAR_STATS_ERROR,
  GET_STATS_FAIL,
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
} from "../constant/statsConstant";

// GET ADMIN STATS REDUCER
export const adminStatsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_STATS_REQUEST:
      return {
        loading: true,
        statsInfo: {},
      };
    case GET_STATS_SUCCESS:
      return {
        loading: false,
        isAuthenticated: true,
        statsInfo: action.payload,
      };
    case GET_STATS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_STATS_ERROR:
      return {
        statsInfo: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
