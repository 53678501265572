import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const ViewResult = () => {
  const location = useLocation();

  const [data, setData] = useState();

  if (location.state && !data) {
    setData({ __html: location.state });
  }


  return (
    <>
      {location.state ? (
        <div className="printAbleDiv" dangerouslySetInnerHTML={data}></div>
      ) : (
        <div>
          Certificate is not Authenticated =={" "}
          <Link to="/" style={{ color: "red", textDecoration: "underline" }}>
            Home
          </Link>
        </div>
      )}
    </>
  );
};

export default ViewResult;
