import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../context/notificationContext";
import {
  clearWalletErrors,
  clearWalletState,
  getAllTransactionAction,
  updateTransactionStatus,
} from "../../redux/action/walletAction";
import Loader from "../reusable/Loader";
import DetailsPopup from "../reusable/DetailsPopUp";
import NoDataCard from "../reusable/NoDataCard";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import moment from "moment";
import ProfileLayout from "../layouts/SidebarLayout";
import Layout from "../layouts/Layout";
import "../../styles/transactionList.scss";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  InputBase,
  Switch,
} from "@material-ui/core";

const TransactionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [, , showNotification] = useNotification();

  const { loading, error, transactions } = useSelector(
    (state) => state.getAllTransaction
  );

  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  // PAGINATION FUNCTION ENDS

  useEffect(() => {
    dispatch(getAllTransactionAction());
  }, [dispatch]);

  //   HANLDE TRANSACTION LIST ERROR
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearWalletErrors());
    }
  }, [error, dispatch, showNotification]);

  const addTransaction = () => {
    navigate("/admin/view-details");
  };

  const [transaction, setTransaction] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);

  const handleViewDetails = (details) => {
    setTransaction(!transaction);
    setTransactionDetails(details);
  };

  //   HANLDE TRANSACTION APPROVE
  const handleApprove = (data) => {
    dispatch(updateTransactionStatus(data));
  };

  const {
    error: approveError,
    loading: updateLoading,
    transactionData,
  } = useSelector((state) => state.approveTransaction);

  useEffect(() => {
    if (approveError) {
      showNotification("error", approveError);
    }
    if (transactionData?.success) {
      showNotification("success", transactionData?.message);
      dispatch(clearWalletState);
    }
  }, [
    showNotification,
    dispatch,
    approveError,
    transactionData?.success,
    transactionData?.message,
  ]);

  return (
    <Fragment>
      {loading || updateLoading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="transaction_list">
              <div className="title">
                <h2>Transaction List</h2>
                <button className="create_btn" onClick={() => addTransaction()}>
                  <FaPlus />
                </button>
              </div>
              {transactions?.transactions?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <b>User Name</b>
                          </TableCell>
                          <TableCell>
                            <b>Email</b>
                          </TableCell>
                          <TableCell>
                            <b>Contact</b>
                          </TableCell>
                          <TableCell>
                            <b>Amount Paid</b>
                          </TableCell>
                          <TableCell>
                            <b>Payment Mode</b>
                          </TableCell>
                          <TableCell>
                            <b>Transaction Id</b>
                          </TableCell>
                          <TableCell>
                            <b>Payment Date</b>
                          </TableCell>
                          <TableCell>
                            <b>Status</b>
                          </TableCell>
                          <TableCell>
                            <b>Approve Payment</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactions?.transactions
                          ?.filter(
                            (course) =>
                              course?.paidBy
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              course?.user?.fullName
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              course?.user?.email
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.user?.fullName}</TableCell>
                              <TableCell>{ele?.user?.email}</TableCell>
                              <TableCell>{ele?.user?.phone}</TableCell>
                              <TableCell>{ele?.amount}</TableCell>
                              <TableCell>{ele?.paidBy}</TableCell>
                              <TableCell>{ele?.txnId}</TableCell>
                              <TableCell>
                                {moment(ele?.createdAt).format("MMMM-DD-YYYY")}
                              </TableCell>
                              <TableCell className="txt_center">
                                <span
                                  className={`${
                                    ele?.isVerified ? "green" : "red"
                                  }`}
                                >
                                  {ele?.isVerified ? "Verified" : "Un-Verified"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <div className="button_container">
                                  <Switch
                                    checked={ele?.isVerified}
                                    onChange={() => handleApprove(ele)}
                                    color="primary"
                                  />
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="button_container">
                                  <Button
                                    className="edit_btn"
                                    onClick={() => handleViewDetails(ele)}
                                  >
                                    <AiFillEye />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={transactions?.transactions?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no transaction yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
          {transaction && (
            <DetailsPopup
              title="Transaction Detail"
              onClick={handleViewDetails}
              transactionDetails={transactionDetails}
            />
          )}
        </Layout>
      )}
    </Fragment>
  );
};

export default TransactionList;
