import React, { Fragment, useEffect, useState } from "react";
import ManualButton from "../reusable/ManualButton";
import ButtonLoader from "../reusable/ButtonLoader";
import { IoMdArrowRoundBack } from "react-icons/io";
import ProfileLayout from "../layouts/SidebarLayout";
import Layout from "../layouts/Layout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import CustomInput from "../reusable/CustomInput";
import CustomSelect from "../reusable/CustomSelect";
import "../../styles/createUser.scss";
import {
  clearUserErrors,
  clearUserState,
  userRegisterAction,
} from "../../redux/action/userAction";
import { userImg } from "../../assest/images";
import { indianStates } from "../../arrayFiles/stateList";
import {
  courseClearErrors,
  getAllCourses,
} from "../../redux/action/courseAction";
import {
  batchClearErrors,
  getAllBatchist,
} from "../../redux/action/batchAction";

const CreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    city: "",
    pinCode: "",
    village: "",
    fatherName: "",
    dob: "",
    password: "",
    confirmPassword: "",
  });
  const [role] = useState(11);
  const [gender, setGender] = useState();
  const [state, setState] = useState();

  const [image, setImage] = useState();
  const [avatarPreview, setAvatarPreview] = useState(userImg);
  const [course, setCourse] = useState();
  const [batch, setBatch] = useState();

  const {
    // eslint-disable-next-line
    loading: courseLoading,
    error: courseError,
    courses: data,
  } = useSelector((state) => state.getCourses);

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(courseClearErrors());
    }
  }, [dispatch, courseError, showNotification]);

  let courseOption = [];

  for (let i = 0; i < data?.courses?.length; i++) {
    let optionData = {
      value: data?.courses[i]?._id,
      label: data?.courses[i]?.title,
    };
    courseOption.push(optionData);
  }
 // Batch functions starts
  const {
    loading: batchLoading,
    error: batchError,
    batchList,
  } = useSelector((state) => state.getAllBatch);

  useEffect(() => {
    dispatch(getAllBatchist());
  }, [dispatch]);

  useEffect(() => {
    if (batchError) {
      showNotification("error", batchError);
      dispatch(batchClearErrors());
    }
  }, [dispatch, batchError, showNotification]);

  let batchOption = [];

  for (let i = 0; i < batchList?.batches?.length; i++) {
    let optionData = {
      value: batchList?.batches[i]?._id,
      label: batchList?.batches[i]?.title,
    };
    batchOption.push(optionData);
  }

  // Batch functions ends

  const handleInputChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setImage(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // GET REGISTER USER STATE FROM STORE
  const { error, userInfo, loading } = useSelector((state) => state.register);

  // DESTRUCTURE ALL FORM VALUE
  const {
    fullName,
    email,
    phone,
    city,
    village,
    pinCode,
    fatherName,
    dob,
    password,
    confirmPassword,
  } = formData;

  // CREATE USER FUNCTION
  const handleSubmit = () => {
    if (password.length < 8) {
      showNotification("error", "Password must have 8 characters");
    } else if (password !== confirmPassword) {
      showNotification("error", "Password and confirm password must be same!");
    } else if (
      fullName === "" &&
      email === "" &&
      phone === "" &&
      state === "" &&
      city === "" &&
      village === "" &&
      pinCode === "" &&
      gender === "" &&
      image === "" &&
      batch === ""
    ) {
      showNotification("error", "Please fill all fields carefully");
    } else {
      const roleValue = parseInt(role);
      dispatch(
        userRegisterAction(
          fullName,
          email,
          phone,
          state,
          city,
          village,
          pinCode,
          fatherName,
          dob,
          password,
          image,
          gender,
          roleValue,
          course,
          batch
        )
      );
    }
  };

  // HANDLE ERROR AFTER USER CREATION
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearUserErrors());
    }
    if (userInfo?.success) {
      showNotification("success", userInfo?.message);
      dispatch(clearUserState());
      navigate("/admin/students");
    }
  }, [
    error,
    dispatch,
    userInfo?.message,
    userInfo?.success,
    showNotification,
    navigate,
  ]);
  return (
    <Fragment>
      <Layout>
        <ProfileLayout>
          <div className="create_user">
            <div className="title">
              <button
                className="create_btn"
                onClick={() => navigate("/admin/students")}
              >
                <IoMdArrowRoundBack />
              </button>
              <h2>Student New Addmission</h2>
            </div>
            <div className="course_form">
              <CustomInput
                label="User Name"
                impStar="*"
                placeholder="User Name"
                type="text"
                onChange={handleInput}
                value={formData.fullName}
                name="fullName"
                icon=""
              />
              <CustomInput
                label="User Email"
                placeholder={"User Email"}
                type="email"
                impStar="*"
                onChange={handleInput}
                value={formData.email}
                name={"email"}
                icon=""
              />
              <CustomInput
                label="User Mobile No."
                impStar="*"
                placeholder="User Mobile No."
                type="number"
                onChange={handleInput}
                value={formData.phone}
                name="phone"
                icon=""
              />
              <CustomSelect
                placeholder="State"
                isMulti={false}
                value={state}
                label="Select State"
                className="myInput"
                onChange={(e) => setState(e.target.value)}
                optionArray={indianStates}
                icon=""
              />
              <CustomInput
                label="City"
                impStar="*"
                placeholder="City"
                type="text"
                onChange={handleInput}
                value={formData.city}
                name="city"
                icon=""
              />
              <CustomInput
                label="Village"
                impStar="*"
                placeholder="Village"
                type="text"
                onChange={handleInput}
                value={formData.village}
                name="village"
                icon=""
              />
              <CustomInput
                label="Pincode"
                impStar="*"
                placeholder="Pincode"
                type="number"
                onChange={handleInput}
                value={formData.pinCode}
                name="pinCode"
                icon=""
              />
              <CustomInput
                label="User DOB"
                impStar="*"
                placeholder="User DOB"
                type="date"
                onChange={handleInput}
                value={formData.dob}
                name="dob"
                icon=""
              />
              <CustomSelect
                label="Gender"
                impStar="*"
                isMulti={false}
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                optionArray={[
                  {
                    value: "male",
                    label: "Male",
                  },
                  {
                    value: "female",
                    label: "Female",
                  },
                  {
                    value: "other",
                    label: "Others",
                  },
                ]}
              />
              <CustomInput
                label="User FatherName"
                impStar="*"
                placeholder="User FatherName"
                type="text"
                onChange={handleInput}
                value={formData.fatherName}
                name="fatherName"
                icon=""
              />
              <CustomSelect
                label="Select Course"
                impStar="*"
                isMulti={false}
                value={course}
                onChange={(e) => setCourse(e.target.value)}
                optionArray={courseOption}
                loading={courseLoading}
              />
              <CustomSelect
                label="Choose Batch"
                impStar="*"
                isMulti={false}
                value={batch}
                onChange={(e) => setBatch(e.target.value)}
                optionArray={batchOption}
                loading={batchLoading}
              />
              <CustomInput
                label="User Password"
                impStar="*"
                placeholder="User Password"
                type="password"
                onChange={handleInput}
                value={formData.password}
                name="password"
                icon=""
              />
              <CustomInput
                label="Confirm Password"
                impStar="*"
                placeholder="Confirm Password"
                type="password"
                onChange={handleInput}
                value={formData.confirmPassword}
                name="confirmPassword"
                icon=""
              />

              {/* <CustomSelect
              label="Register As"
              impStar="*"
              isMulti={false}
              value={role}
              onChange={(e) => setRole(e.target.value)}
              optionArray={[
                {
                  value: "10",
                  label: "Admin",
                },
                {
                  value: "11",
                  label: "User",
                },
                {
                  value: "12",
                  label: "Franchise",
                },
              ]}
            /> */}
              <CustomInput
                label="User Image"
                impStar="*"
                placeholder=""
                type="file"
                name="image"
                accept="image/*"
                onChange={handleInputChange}
              />
            </div>
            <div className="image_preview">
              <img src={avatarPreview} alt="user_img" />
            </div>
            <div className="submit_form">
              <ManualButton
                className="create"
                title={loading ? <ButtonLoader /> : "Create"}
                onClick={handleSubmit}
                width={"110px"}
              />
            </div>
          </div>
        </ProfileLayout>
      </Layout>
    </Fragment>
  );
};

export default CreateUser;
