import { axiosInstance } from "../../utils/axiosInstance";
import {
  CLEAR_STATS_ERROR,
  CLEAR_STATS_STATE,
  GET_STATS_FAIL,
  GET_STATS_REQUEST,
  GET_STATS_SUCCESS,
} from "../constant/statsConstant";

export const getStats = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_STATS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-Type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`stats`, config);

    dispatch({
      type: GET_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_STATS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearStatsError = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATS_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearStatState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATS_STATE });
};
