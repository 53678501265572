import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import NoDataCard from "../reusable/NoDataCard";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/userList.scss";
import Loader from "../reusable/Loader";
import moment from "moment/moment";
import PopupPrompt from "../reusable/PopupPrompt";
import {
  clearUserErrors,
  clearUserState,
  deleteUser,
  getAllUsers,
} from "../../redux/action/userAction";
import DetailsPopUp from "../reusable/DetailsPopUp";

// Import Material-UI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TableSortLabel,
  TablePagination,
  InputBase,
} from "@material-ui/core";

const UserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [popupMsg, setPopupMsg] = useState({});
  const [details, setDetails] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  // PAGINATION FUNCTION ENDS

  const {
    loading,
    error,
    userInfo: data,
  } = useSelector((state) => state.getAllUser);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearUserErrors());
    }
  }, [dispatch, error, showNotification]);

  // DELETE COUIRSE STATE GET FROM REDUCER
  const {
    loading: userDeleteLoading,
    userDetails,
    error: userDeleteError,
  } = useSelector((state) => state.deleteUser);

  // HANLDE DELETE COURSE
  const handleDelete = (id) => {
    dispatch(deleteUser(id));
  };

  useEffect(() => {
    if (userDeleteError) {
      showNotification("error", userDeleteError);
    }
    if (userDetails?.success) {
      showNotification("success", userDetails?.message);
      dispatch(getAllUsers());
      setPopupMsg(false);
      dispatch(clearUserState());
    }
  }, [
    userDetails?.success,
    userDeleteError,
    dispatch,
    showNotification,
    userDetails?.message,
  ]);

  const handleViewDetails = (userDetails) => {
    setDetails(() => !details);
    setUserInfo(userDetails);
  };

  const createUser = () => {
    navigate("/admin/create-student");
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="user_list">
              <div className="title">
                <h2>All Student</h2>
                <button className="create_btn" onClick={() => createUser()}>
                  <FaPlus />
                </button>
              </div>
              {data?.users?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search by user name…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel>
                              <b>User Name</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <b>Father Name</b>
                          </TableCell>
                          <TableCell>
                            <b>Email</b>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>
                              <b>Phone</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <b>Gender</b>
                          </TableCell>
                          <TableCell>
                            <b>Role</b>
                          </TableCell>
                          <TableCell>
                            <b>Created Date & Time</b>
                          </TableCell>
                          <TableCell>
                            <b>Status</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.users
                          ?.filter(
                            (item) =>
                              item.fullName
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) &&
                              item?.role === 11
                          )

                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.fullName}</TableCell>
                              <TableCell>{ele?.fatherName}</TableCell>
                              <TableCell>{ele?.email}</TableCell>
                              <TableCell>{ele?.phone}</TableCell>
                              <TableCell>{ele?.gender}</TableCell>
                              <TableCell>
                                <p className={ele?.role === 10 && "green"}>
                                  {ele?.role === 10
                                    ? "Admin"
                                    : ele?.role === 11
                                    ? "Student"
                                    : ele?.role === 12
                                    ? "Franchise"
                                    : "Unknown Role"}
                                </p>
                              </TableCell>
                              <TableCell>
                                {moment(ele?.createdAt).format(
                                  "MMMM-DD-YYYY [at] h:mm A"
                                )}
                              </TableCell>
                              <TableCell className="txt_center">
                                <span
                                  className={`${
                                    ele?.isActive ? "green" : "red"
                                  }`}
                                >
                                  {ele?.isActive ? "Active" : "In Active"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <div className="button_container">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleViewDetails(ele)}
                                  >
                                    <AiFillEye />
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      setPopupMsg({ show: true, id: ele._id })
                                    }
                                  >
                                    <MdDelete />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.users?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no student registered yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
        </Layout>
      )}

      {/* View user POPUP START */}
      {details && (
        <DetailsPopUp
          title="Student Detail"
          onClick={handleViewDetails}
          details={userInfo}
        />
      )}
      {/* View user POPUP ENDS */}

      {/* DELETE COURSE POPUP START */}
      {popupMsg.show && (
        <PopupPrompt
          confirmBtnTxt="Confirm"
          cancelBtnTxt="Cancel"
          title="Are you sure! You want to delete this user."
          confirmOnclick={() => handleDelete(popupMsg?.id)}
          cancelOnclick={() => setPopupMsg(false)}
          loading={userDeleteLoading}
        />
      )}
      {/* DELETE COURSE POPUP ENDS */}
    </Fragment>
  );
};

export default UserList;
