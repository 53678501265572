import { axiosInstance } from "../../utils/axiosInstance";
import {
  ALL_EXAM_FAIL,
  ALL_EXAM_REQUEST,
  ALL_EXAM_SUCCESS,
  EXAM_DETAIL_FAIL,
  EXAM_DETAIL_REQUEST,
  EXAM_DETAIL_SUCCESS,
  CLEAR_EXAM_DELETE_STATE,
  CLEAR_EXAM_ERRORS,
  CLEAR_EXAM_STATE,
  CREATE_EXAM_FAIL,
  CREATE_EXAM_REQUEST,
  CREATE_EXAM_SUCCESS,
  DELETE_EXAM_FAIL,
  DELETE_EXAM_REQUEST,
  DELETE_EXAM_SUCCESS,
  UPDATE_EXAM_FAIL,
  UPDATE_EXAM_REQUEST,
  UPDATE_EXAM_SUCCESS,
  PRINT_HALL_TICKET_REQUEST,
  PRINT_HALL_TICKET_SUCCESS,
  PRINT_HALL_TICKET_FAIL,
} from "../constant/examConstant";

export const createExam =
  (title, date, time, location, course, batch, students) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_EXAM_REQUEST,
      });
      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      let stringstudents;

      if (students) {
        stringstudents = JSON.stringify(students);
      }
      const { data } = await axiosInstance.post(
        "/create-hall-ticket",
        {
          title,
          date,
          time,
          location,
          course,
          batch,
          students: stringstudents,
        },
        config
      );
      dispatch({
        type: CREATE_EXAM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_EXAM_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE COURSE DETAILS
export const updateEXAM =
  (id, title, time, studentLimit) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_EXAM_REQUEST,
      });
      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.put(
        `/EXAM/${id}`,
        {
          title,
          time,
          studentLimit,
        },
        config
      );
      dispatch({
        type: UPDATE_EXAM_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_EXAM_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL COURSES
export const getAllExamList = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_EXAM_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/my-created-ht", config);

    dispatch({
      type: ALL_EXAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_EXAM_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET COURSE DETAIL BY ID
export const getEXAMDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: EXAM_DETAIL_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/exam/${id}`, config);

    dispatch({
      type: EXAM_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: EXAM_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const printHallTicket = (courseId, studentId) => async (dispatch) => {
  try {
    dispatch({
      type: PRINT_HALL_TICKET_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(
      `/my-ht/${courseId}/${studentId}`,
      config
    );

    dispatch({
      type: PRINT_HALL_TICKET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRINT_HALL_TICKET_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE COURSE --- ADMIN
export const deleteEXAM = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_EXAM_REQUEST,
    });

    const localStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/EXAM/${id}`, config);

    dispatch({
      type: DELETE_EXAM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_EXAM_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const EXAMClearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_EXAM_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const EXAMClearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_EXAM_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearEXAMDeleteState = () => async (dispatch) => {
  dispatch({ type: CLEAR_EXAM_DELETE_STATE });
};
