import {
  APPROVE_PAYMENT_FAIL,
  APPROVE_PAYMENT_REQUEST,
  APPROVE_PAYMENT_SUCCESS,
  CLEAR_WALLET_TRANSACTION_ERROR,
  CLEAR_WALLET_TRANSACTION_STATE,
  GET_SELF_CREATED_TRANSACTION_FAIL,
  GET_SELF_CREATED_TRANSACTION_REQUEST,
  GET_SELF_CREATED_TRANSACTION_SUCCESS,
  RECHARGE_WALLET_FAIL,
  RECHARGE_WALLET_REQUEST,
  RECHARGE_WALLET_SUCCESS,
} from "../constant/walletConstant";
import { axiosInstance } from "../../utils/axiosInstance";
// FOR USER REGISTRATION
export const rechargeWalletAction =
  (amount, txnId, paidBy) => async (dispatch) => {
    try {
      dispatch({
        type: RECHARGE_WALLET_REQUEST,
      });
      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/fund-request",
        {
          amount,
          txnId,
          paidBy,
        },
        config
      );
      dispatch({
        type: RECHARGE_WALLET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RECHARGE_WALLET_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const getAllTransactionAction = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SELF_CREATED_TRANSACTION_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/all-txn", config);
    dispatch({
      type: GET_SELF_CREATED_TRANSACTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_SELF_CREATED_TRANSACTION_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const updateTransactionStatus = (details) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_PAYMENT_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };
    const { data } = await axiosInstance.put(
      `/approve-txn/${details?._id}`,
      {
        amount: details?.amount,
        verify: true,
        status: "Verified",
      },
      config
    );
    dispatch({
      type: APPROVE_PAYMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVE_PAYMENT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearWalletErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_WALLET_TRANSACTION_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearWalletState = () => async (dispatch) => {
  dispatch({ type: CLEAR_WALLET_TRANSACTION_STATE });
};
