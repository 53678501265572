import React, { Fragment } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import RedirectSpinner from "./components/reusable/RedirectSpinner";
import NotificationPrompt from "./components/reusable/NotificationPrompt";
import Loader from "./components/reusable/Loader";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Courses from "./pages/Courses";
import CreateCourse from "./components/Course/CreateCourse";
import Franchise from "./pages/Franchise";
import UpdateCourse from "./components/Course/EditCourse";
import MyProfile from "./pages/MyProfile";
import User from "./pages/User";
import CreateUser from "./components/users/CreateUser";
import ResultList from "./components/result/ResultList";
import CreateResult from "./components/result/CreateResult";
import ViewResult from "./components/result/ViewResult";
import CategoryList from "./components/Category/CategoryList";
import CategoryCreate from "./components/Category/CategoryCreate";
import BatchList from "./components/batches/BatchList";
import CreateBatch from "./components/batches/CreateBatch";
import EditBatch from "./components/batches/EditBatch";
import CategoryEdit from "./components/Category/CategoryEdit";
import CreateFranchise from "./components/Franchise/CreateFranchise";
import TransactionList from "./components/wallet/TransactionList";
import Exam from "./pages/Exam";
import CreateExam from "./components/exams/CreateExam";
import ViewStudentList from "./components/exams/ViewStudentList";
import CreateMarksheet from "./components/marksheet/CreateMarksheet";
import OutMarksheet from "./components/marksheet/OutMarksheet";
import ViewMarksheet from "./components/marksheet/ViewMarksheet";

const App = () => {
  const { loading, isAuthenticated, adminInfo } = useSelector(
    (state) => state.profile
  );

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="bg_color">
          <NotificationPrompt />
          <Routes>
            <Route path="/" element={<Login />} />
            {/* LOGGED_IN STUDENT ROUTES */}
            <Route
              path="/admin"
              element={
                isAuthenticated && adminInfo?.user?._id ? (
                  <Outlet />
                ) : (
                  <RedirectSpinner />
                )
              }
            >
              <Route path="profile" element={<MyProfile />} />
              <Route path="dashboard" element={<Home />} />

              {/* COURSES ROUTE START */}
              <Route path="courses" element={<Courses />} />
              <Route path="create-course" element={<CreateCourse />} />
              <Route path="update-course/:id" element={<UpdateCourse />} />
              {/* COURSES ROUTE ENDS */}

              {/* RESULT ROUTES START */}
              <Route path="results" element={<ResultList />} />
              <Route path="create-result" element={<CreateResult />} />
              <Route path="view-result/:name" element={<ViewResult />} />
              {/* RESULT ROUTES ENDS */}

              {/* USER ROUTES START */}
              <Route path="students" element={<User />} />
              <Route path="create-student" element={<CreateUser />} />
              <Route path="update-student/:id" element={<UpdateCourse />} />
              {/* USER ROUTES ENDS */}

              <Route path="franchise" element={<Franchise />} />
              <Route path="franchise-create" element={<CreateFranchise />} />

              {/* CATEGORY ROUTES STARTS */}
              <Route path="category" element={<CategoryList />} />
              <Route path="create-category" element={<CategoryCreate />} />
              <Route path="update-category/:id" element={<CategoryEdit />} />
              {/* CATEGORY ROUTES ENDS */}

              {/* BATCH ROUTES STARTS */}
              <Route path="batches" element={<BatchList />} />
              <Route path="create-batch" element={<CreateBatch />} />
              <Route path="update-batch/:id" element={<EditBatch />} />
              {/* BATCH ROUTES ENDS */}

              {/* EXAMS ROUTES START */}
              <Route path="exams" element={<Exam />} />
              <Route path="create-exam" element={<CreateExam />} />
              <Route path="update-batch/:id" element={<EditBatch />} />
              <Route path="exams/student-list" element={<ViewStudentList />} />

              {/* EXAMS ROUTES ENDS */}

              <Route path="out-marksheet" element={<OutMarksheet />} />
              <Route path="create-marksheet" element={<CreateMarksheet />} />
              <Route path="transaction" element={<TransactionList />} />
              <Route
                path="/admin/view-marksheet/certificate"
                element={<ViewMarksheet />}
              />

              {/*
             
              <Route path="course-detail/:id" element={<CourseDetails />} />
              <Route path="profile" element={<MyProfile />} />
              <Route path="view-result" element={<VerifyCertificate />} /> */}
            </Route>
          </Routes>
        </div>
      )}
    </Fragment>
  );
};

export default App;
