import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import { thunk } from "redux-thunk";
// import { composeWithDevTools } from "redux-devtools-extension";
import {
  deleteUserReducer,
  getAllUserReducer,
  getUserDetailsReducer,
  updateUserByIdReducer,
  userRegister,
} from "./reducer/userReducer";
import {
  courseCreateReducer,
  courseUpdateReducer,
  deleteCourseReducer,
  getCourseDetailsReducer,
  getCoursesReducer,
} from "./reducer/courseReducer";
import {
  findCertificateReducer,
  getAllResultReducer,
  resultCreateReducer,
  updateResultReducer,
} from "./reducer/certificateReducer";
import {
  adminLoginReducer,
  adminProfileReducer,
  removeProfilePic,
  updateProfilePicReducer,
  updateProfileReducer,
} from "./reducer/adminReducer";
import { adminStatsReducer } from "./reducer/statsReducer";
import {
  categoryCreateReducer,
  categoryUpdateReducer,
  deleteCategoryReducer,
  getAllCategoryReducer,
  getCategoryDetailsReducer,
} from "./reducer/categoryReducer";
import {
  batchCreateReducer,
  batchUpdateReducer,
  deleteBatchReducer,
  getAllBatchReducer,
  getBatchDetailsReducer,
} from "./reducer/batchReducer";
import {
  approveTransactionReducer,
  getAllTransactionReducer,
} from "./reducer/walletReducer";
import {
  examCreateReducer,
  getAllExamReducer,
  printHallTicketReducer,
} from "./reducer/examReducer";
import {
  findMarksheetReducer,
  getAllMarksheetReducer,
  markSheetCreateReducer,
  updateMarksheetReducer,
} from "./reducer/markSheetReducer";

// get user from sessionStorage
const userFromLocalStorage = sessionStorage.getItem("niotamAdminToken")
  ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
  : {};

// COMBINE ALL REDUCERS
const reducer = combineReducers({
  register: userRegister,
  getAllUser: getAllUserReducer,
  getUserDetaiLS: getUserDetailsReducer,
  updateUserById: updateUserByIdReducer,
  deleteUser: deleteUserReducer,
  userLogin: adminLoginReducer,
  profile: adminProfileReducer,
  updateProfile: updateProfileReducer,
  userAvatar: updateProfilePicReducer,
  removeAvatar: removeProfilePic,
  createResult: resultCreateReducer,
  getAllResult: getAllResultReducer,
  findCertificate: findCertificateReducer,
  getCourses: getCoursesReducer,
  createCourse: courseCreateReducer,
  courseUpdate: courseUpdateReducer,
  courseDetail: getCourseDetailsReducer,
  courseDelete: deleteCourseReducer,
  getAllCategory: getAllCategoryReducer,
  getCategoryDetails: getCategoryDetailsReducer,
  categoryCreate: categoryCreateReducer,
  categoryUpdate: categoryUpdateReducer,
  deleteCategory: deleteCategoryReducer,
  batchCreate: batchCreateReducer,
  batchUpdate: batchUpdateReducer,
  getAllBatch: getAllBatchReducer,
  getBatchDetails: getBatchDetailsReducer,
  deleteBatch: deleteBatchReducer,
  updateResult: updateResultReducer,

  getAllTransaction: getAllTransactionReducer,
  approveTransaction: approveTransactionReducer,
  getAllExam: getAllExamReducer,
  examCreate: examCreateReducer,
  printHallTicket: printHallTicketReducer,

  markSheetCreate: markSheetCreateReducer,
  getAllMarksheet: getAllMarksheetReducer,
  findMarksheet: findMarksheetReducer,
  updateMarksheet: updateMarksheetReducer,

  adminStats: adminStatsReducer,
});

// INITIAL STATE
const initialState = {
  userLogin: {
    adminInfo: userFromLocalStorage,
  },
};

// MIDDLEWARE // THUNK
const middleware = [thunk];

// const store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

const store = createStore(
  reducer,
  initialState,
  compose(applyMiddleware(...middleware))
);

export default store;
