export const headerLinks = [
  {
    id: 1,
    name: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    id: 2,
    name: "Students",
    link: "/admin/students",
  },
  {
    id: 3,
    name: "Courses",
    link: "/admin/courses",
  },
  {
    id: 4,
    name: "Franchise",
    link: "/admin/franchise",
  },
  {
    id: 5,
    name: "Course Category",
    link: "/admin/category",
  },
  {
    id: 6,
    name: "Student Results",
    link: "/admin/results",
  },
  {
    id: 10,
    name: "Student Marksheet",
    link: "/admin/out-marksheet",
  },
  {
    id: 7,
    name: "Manage Batches",
    link: "/admin/batches",
  },
  {
    id: 9,
    name: "Exams & Hall Ticket",
    link: "/admin/exams",
  },
  {
    id: 8,
    name: "Transaction History",
    link: "/admin/transaction",
  },
];
