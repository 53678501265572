import React, { Fragment, useEffect } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import NoDataCard from "../reusable/NoDataCard";
import { FaPlus, FaRegSadTear } from "react-icons/fa";
// import { AiFillEye } from "react-icons/ai";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/resultList.scss";
import Loader from "../reusable/Loader";
// import moment from "moment/moment";
import {
  clearDownloadErrors,
  clearDownloadState,
  getAllResult,
  updateResult,
  viewCertificate,
} from "../../redux/action/certificateAction";
import { AiFillEye } from "react-icons/ai";
import ButtonLoader from "../reusable/ButtonLoader";
import { clearUpdateState } from "../../redux/action/adminAction";
const ResultList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const {
    loading,
    error,
    resultList: data,
  } = useSelector((state) => state.getAllResult);

  const allResult = data?.resultList;

  useEffect(() => {
    dispatch(getAllResult());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearDownloadErrors());
    }
  }, [dispatch, error, showNotification]);

  //   VIEW RESULT

  const {
    loading: certificateLoading,
    certificateInfo,
    error: certificateError,
  } = useSelector((state) => state.findCertificate);
  const handleViewDetails = (ele) => {
    const roll_no = ele?.student?.rollNo;
    const course_id = ele?.course?._id;
    if (roll_no && course_id) {
      dispatch(viewCertificate(roll_no, course_id));
    } else {
      showNotification("error", "Please fill all the required fields.");
    }
  };

  useEffect(() => {
    if (certificateError) {
      showNotification("error", certificateError);
      dispatch(clearDownloadErrors());
    }

    if (typeof certificateInfo == "string") {
      dispatch(clearDownloadState());
      navigate("/admin/view-result/certificate", { state: certificateInfo });
    }
  }, [
    dispatch,
    showNotification,
    error,
    certificateInfo,
    certificateError,
    navigate,
  ]);

  // CREATE RESULT OF USER
  const createResult = () => {
    navigate("/admin/create-result");
  };

  // UPDATE RESULT
  const handleApprove = (id) => {
    dispatch(updateResult(id, {isActive :true}));
  };

  const {
    loading: updateLoading,
    error: updateError,
    resultInfo,
  } = useSelector((state) => state.updateResult);

  useEffect(() => {
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearDownloadErrors());
    }
    if (resultInfo?.success) {
      showNotification("success", resultInfo?.message);
      dispatch(clearUpdateState());
      dispatch(getAllResult());
    }
  }, [
    updateError,
    dispatch,
    showNotification,
    resultInfo?.message,
    resultInfo?.success,
  ]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="result_list">
              <div className="title">
                <h2>Student Results</h2>
                <button className="create_btn" onClick={() => createResult()}>
                  <FaPlus />
                </button>
              </div>
              {allResult?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="timing_table customScroll">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Student Name</th>
                          <th>Roll No.</th>
                          <th>Course Name</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Grade</th>
                          <th>Duration</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allResult?.map((ele, idx) => (
                          <tr key={ele._id}>
                            <td>
                              <p>{ele?.student?.fullName}</p>
                            </td>
                            <td>
                              <p>{ele?.student?.rollNo}</p>
                            </td>
                            <td>
                              <p>{ele?.course?.category}</p>
                            </td>
                            <td>
                              <p>{ele?.session?.startDate}</p>
                            </td>
                            <td>
                              <p>
                                {ele?.session?.endDate}
                                {/* {moment(ele?.session?.endDate).format(
                                  "MMMM-DD-YYYY"
                                )} */}
                              </p>
                            </td>
                            <td>{ele?.grade}</td>
                            <td>{ele?.duration}</td>

                            <td className="txt_center">
                              <span
                                className={`${ele?.isActive ? "green" : "red"}`}
                              >
                                {ele?.isActive ? "Declared" : "Not Approved"}
                              </span>
                            </td>
                            <td>
                              <button
                                title="View/Edit Result"
                                onClick={() => handleViewDetails(ele)}
                              >
                                {certificateLoading ? (
                                  <ButtonLoader />
                                ) : (
                                  <AiFillEye />
                                )}
                              </button>
                              {!ele?.isActive && (
                                <button
                                  title="Approve Result"
                                  onClick={() => handleApprove(ele?._id)}
                                >
                                  {updateLoading ? (
                                    <ButtonLoader />
                                  ) : (
                                    "Approve Result"
                                  )}
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <NoDataCard
                  txt="There is no result added yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
        </Layout>
      )}
    </Fragment>
  );
};

export default ResultList;
