import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import NoDataCard from "../reusable/NoDataCard";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { MdDelete } from "react-icons/md";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/courseList.scss";
import Loader from "../reusable/Loader";
import {
  courseClearErrors,
  clearDeleteState,
  deleteCourse,
  getAllCourses,
} from "../../redux/action/courseAction";
import moment from "moment/moment";
import PopupPrompt from "../reusable/PopupPrompt";

// Import Material-UI components
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  TableSortLabel,
  InputBase,
} from "@material-ui/core";

const CourseList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // SHOW POPUP MSG STATE
  const [popupMsg, setPopupMsg] = useState({});

  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

 
  // PAGINATION FUNCTION ENDS

  const {
    loading,
    error,
    courses: data,
  } = useSelector((state) => state.getCourses);

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(courseClearErrors());
    }
  }, [dispatch, error, showNotification]);

  // DELETE COUIRSE STATE GET FROM REDUCER
  const {
    loading: courseDeleteLoading,
    course,
    error: courseDeleteError,
  } = useSelector((state) => state.courseDelete);

  // HANLDE DELETE COURSE
  const handleDelete = (id) => {
    dispatch(deleteCourse(id));
  };
  useEffect(() => {
    if (courseDeleteError) {
      showNotification("error", courseDeleteError);
    }
    if (course?.success) {
      showNotification("success", course?.message);
      dispatch(getAllCourses());
      setPopupMsg(false);
      dispatch(clearDeleteState());
    }
  }, [
    course?.success,
    courseDeleteError,
    dispatch,
    showNotification,
    course?.message,
  ]);

  const handleViewDetails = (courseId) => {
    navigate(`/admin/update-course/${courseId}`);
  };

  const createCourse = () => {
    navigate("/admin/create-course");
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="course_list">
              <div className="title">
                <h2>Courses</h2>
                <button className="create_btn" onClick={() => createCourse()}>
                  <FaPlus />
                </button>
              </div>
              {data?.courses?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel
                              // active={orderBy === "title"}
                              // direction={orderBy === "title" ? order : "asc"}
                              // onClick={() => handleSort("title")}
                            >
                              <b>Course Name</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel
                              // active={orderBy === "price"}
                              // direction={orderBy === "price" ? order : "asc"}
                              // onClick={() => handleSort("price")}
                            >
                              <b>Course Fee</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <b>Created Date & Time</b>
                          </TableCell>
                          <TableCell>
                            <b>Status</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.courses
                          ?.filter((course) =>
                            course.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.title}</TableCell>
                              <TableCell>{ele?.price}</TableCell>
                              <TableCell>
                                {moment(ele?.createdAt).format(
                                  "MMMM-DD-YYYY [at] h:mm A"
                                )}
                              </TableCell>
                              <TableCell className="txt_center">
                                <span
                                  className={`${
                                    ele?.isActive ? "green" : "red"
                                  }`}
                                >
                                  {ele?.isActive ? "Active" : "Ended"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <div className="button_container">
                                  <Button
                                    className="edit_btn"
                                    onClick={() => handleViewDetails(ele?._id)}
                                  >
                                    <AiFillEye />
                                  </Button>
                                  <Button
                                    className="delete_btn"
                                    onClick={() =>
                                      setPopupMsg({ show: true, id: ele._id })
                                    }
                                  >
                                    <MdDelete />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.courses?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no course added yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
        </Layout>
      )}

      {/* DELETE COURSE POPUP START */}
      {popupMsg.show && (
        <PopupPrompt
          confirmBtnTxt="Confirm"
          cancelBtnTxt="Cancel"
          title="Are you sure! You want to delete this course."
          confirmOnclick={() => handleDelete(popupMsg?.id)}
          cancelOnclick={() => setPopupMsg(false)}
          loading={courseDeleteLoading}
        />
      )}
      {/* DELETE COURSE POPUP ENDS */}
    </Fragment>
  );
};

export default CourseList;
