export const CREATE_COURSE_REQUEST = "CREATE_COURSE_REQUEST";
export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_FAIL = "CREATE_COURSE_FAIL";

export const UPDATE_COURSE_REQUEST = "UPDATE_COURSE_REQUEST";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAIL = "UPDATE_COURSE_FAIL";

export const ALL_COURSES_REQUEST = "ALL_COURSES_REQUEST";
export const ALL_COURSES_SUCCESS = "ALL_COURSES_SUCCESS";
export const ALL_COURSES_FAIL = "ALL_COURSES_FAIL";

export const COURSE_DETAIL_REQUEST = "COURSE_DETAIL_REQUEST";
export const COURSE_DETAIL_SUCCESS = "COURSE_DETAIL_SUCCESS";
export const COURSE_DETAIL_FAIL = "COURSE_DETAIL_FAIL";

export const DELETE_COURSE_REQUEST = "DELETE_COURSE_REQUEST";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAIL = "DELETE_COURSE_FAIL";
export const CLEAR_DELETE_STATE = "CLEAR_DELETE_STATE";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CLEAR_STATE = "CLEAR_STATE";


