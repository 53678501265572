import {
  ALL_COURSES_FAIL,
  ALL_COURSES_REQUEST,
  ALL_COURSES_SUCCESS,
  CLEAR_DELETE_STATE,
  CLEAR_ERRORS,
  CLEAR_STATE,
  COURSE_DETAIL_FAIL,
  COURSE_DETAIL_REQUEST,
  COURSE_DETAIL_SUCCESS,
  CREATE_COURSE_FAIL,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  UPDATE_COURSE_FAIL,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
} from "../constant/courseConstant";

export const courseCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COURSE_REQUEST:
      return {
        loading: true,
        courseData: {},
      };
    case CREATE_COURSE_SUCCESS:
      return {
        loading: false,
        courseData: action.payload,
      };
    case CREATE_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        courseData: {},
      };

    default:
      return state;
  }
};

// UPDATE COURSE REDUCER
export const courseUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_COURSE_REQUEST:
      return {
        loading: true,
        courseData: {},
      };
    case UPDATE_COURSE_SUCCESS:
      return {
        loading: false,
        courseData: action.payload,
      };
    case UPDATE_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case CLEAR_STATE:
      return {
        loading: false,
        courseData: {},
      };

    default:
      return state;
  }
};

// GET ALL COURSES REDUCER
export const getCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_COURSES_REQUEST:
      return {
        loading: true,
        courses: {},
      };
    case ALL_COURSES_SUCCESS:
      return {
        loading: false,
        courses: action.payload,
      };
    case ALL_COURSES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        courses: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// GET COURSE DETAILS REDUCER
export const getCourseDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_DETAIL_REQUEST:
      return {
        loading: true,
        courseDetails: {},
      };
    case COURSE_DETAIL_SUCCESS:
      return {
        loading: false,
        courseDetails: action.payload,
      };
    case COURSE_DETAIL_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        courseDetails: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};

// DELETE COURSE DETAILS REDUCER
export const deleteCourseReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_COURSE_REQUEST:
      return {
        loading: true,
        course: {},
      };
    case DELETE_COURSE_SUCCESS:
      return {
        loading: false,
        course: action.payload,
      };
    case DELETE_COURSE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CLEAR_DELETE_STATE:
      return {
        course: {},
        loading: false,
        error: null,
      };

    default:
      return state;
  }
};
