export const USER_REGISER_REQUEST = "USER_REGISER_REQUEST";
export const USER_REGISER_SUCCESS = "USER_REGISER_SUCCESS";
export const USER_REGISER_FAIL = "USER_REGISER_FAIL";

export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";

export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_USER_BY_ID_REQUEST = "UPDATE_USER_BY_ID_REQUEST";
export const UPDATE_USER_BY_ID_SUCCESS = "UPDATE_USER_BY_ID_SUCCESS";
export const UPDATE_USER_BY_ID_FAIL = "UPDATE_USER_BY_ID_FAIL";


export const USER_CLEAR_ERROR = "USER_CLEAR_ERROR";
export const USER_CLEAR_STATE = "USER_CLEAR_STATE";
