import React, { Fragment,  useState } from "react";
import "../../styles/header.scss";
import { desktop_logo, userImg } from "../../assest/images";
import { Link, useNavigate } from "react-router-dom";
import { BsFillCaretDownFill, BsFillCaretUpFill } from "react-icons/bs";
import { useAuth } from "../../context/userContext";
import { logout } from "../../redux/action/adminAction";
import { useDispatch } from "react-redux";
const Header = () => {
  const navigate = useNavigate();
  // EMAIL AND PHONE  ADDRESS
  const [auth, setAuth] = useAuth();
  const dispatch = useDispatch();
  const [profileDropdown, setProfileDropdown] = useState(false);
  const handleLogin = () => {
    navigate("/login");
  };
  const handleRegister = () => {
    navigate("/register");
  };

  // HANDLE LOG_OUT FUNCTION
  const handleLogout = () => {
    dispatch(logout());
    setAuth({
      user: null,
      token: "",
      loading: false,
    })
    navigate("/");
  };

  return (
    <Fragment>
      <div className="navbar_wrapper">
        <div className={`nav_bottom`}>
          <div className="container">
            <div className="nav_main_sec">
              <div className="logo">
                <Link to="/">
                  <img
                    className="desktop"
                    src={desktop_logo}
                    alt="Niotam logo"
                  />
                </Link>
              </div>
              <div className="nav_links">
                <div className="nav_link dropdown_nav">
                  {!auth?.user?._id ? (
                    <div className="headerSign_btns">
                      <button className="sign_up_btn" onClick={handleLogin}>
                        Sign In
                      </button>
                      <button className="sign_up_btn" onClick={handleRegister}>
                        Register
                      </button>
                    </div>
                  ) : (
                    <div className="auth_profile_dropdown">
                      <button
                        className={` ${
                          auth?.user?.fullName ? "profile_header_btn" : ""
                        }`}
                        onClick={() => setProfileDropdown(!profileDropdown)}
                      >
                        <img src={userImg} alt={auth?.user?.fullName} />
                        {auth?.user?.fullName}
                        {profileDropdown ? (
                          <BsFillCaretUpFill />
                        ) : (
                          <BsFillCaretDownFill />
                        )}
                      </button>
                      <div
                        className={`nav_dropdown_links ${
                          profileDropdown ? "show_nav_dropdown" : ""
                        }`}
                      >
                        <Link to="/admin/profile">Profile</Link>
                        <button className="logout_btn" onClick={handleLogout}>
                          logout
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
