import { axiosInstance } from "../../utils/axiosInstance";
import {
  ALL_COURSES_FAIL,
  ALL_COURSES_REQUEST,
  ALL_COURSES_SUCCESS,
  CLEAR_DELETE_STATE,
  CLEAR_ERRORS,
  CLEAR_STATE,
  COURSE_DETAIL_FAIL,
  COURSE_DETAIL_REQUEST,
  COURSE_DETAIL_SUCCESS,
  CREATE_COURSE_FAIL,
  CREATE_COURSE_REQUEST,
  CREATE_COURSE_SUCCESS,
  DELETE_COURSE_FAIL,
  DELETE_COURSE_REQUEST,
  DELETE_COURSE_SUCCESS,
  UPDATE_COURSE_FAIL,
  UPDATE_COURSE_REQUEST,
  UPDATE_COURSE_SUCCESS,
} from "../constant/courseConstant";

export const createCourse =
  (
    title,
    price,
    discountPercentage,
    description,
    category,
    sessionDuration,
    features,
    subjects,
    thumbnail,
    examFees
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CREATE_COURSE_REQUEST,
      });
      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      let stringFeatures = [];

      if (features) {
        stringFeatures = JSON.stringify(features);
      }

      let stringSubjects = [];

      if (subjects) {
        stringSubjects = JSON.stringify(subjects);
      }

      const { data } = await axiosInstance.post(
        "/create-course",
        {
          title,
          price,
          discountPercentage,
          description,
          category,
          sessionDuration,
          features: stringFeatures,
          subjectList: stringSubjects,
          thumbnail,
          examFees,
        },
        config
      );
      dispatch({
        type: CREATE_COURSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CREATE_COURSE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// UPDATE COURSE DETAILS
export const updateCourse =
  (
    id,
    title,
    price,
    discountPercentage,
    description,
    category,
    sessionDuration,
    features,
    subjects,
    thumbnail,
    examFees
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_COURSE_REQUEST,
      });
      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      let stringFeatures = [];

      if (features) {
        stringFeatures = JSON.stringify(features);
      }
      let stringSubjects = [];

      if (subjects) {
        stringSubjects = JSON.stringify(subjects);
      }

      const { data } = await axiosInstance.put(
        `/course/${id}`,
        {
          title,
          price,
          discountPercentage,
          description,
          category,
          sessionDuration,
          features: stringFeatures,
          subjectList: stringSubjects,
          thumbnail,
          examFees,
        },
        config
      );
      dispatch({
        type: UPDATE_COURSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_COURSE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL COURSES
export const getAllCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: ALL_COURSES_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get("/courses", config);

    dispatch({
      type: ALL_COURSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ALL_COURSES_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET COURSE DETAIL BY ID
export const getCourseDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: COURSE_DETAIL_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/course/${id}`, config);

    dispatch({
      type: COURSE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: COURSE_DETAIL_FAIL,
      payload: error.response.data.message,
    });
  }
};

// DELETE COURSE --- ADMIN
export const deleteCourse = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_COURSE_REQUEST,
    });

    const localStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/course/${id}`, config);

    dispatch({
      type: DELETE_COURSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_COURSE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const courseClearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

// CLEAR ERROR ACTION FUNCTION
export const courseClearState = () => async (dispatch) => {
  dispatch({ type: CLEAR_STATE });
};

// CLEAR ERROR ACTION FUNCTION
export const clearDeleteState = () => async (dispatch) => {
  dispatch({ type: CLEAR_DELETE_STATE });
};
