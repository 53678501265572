import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../styles/createCourse.scss";
import CustomSelect from "../reusable/CustomSelect";
import CustomInput from "../reusable/CustomInput";
import CustomTextarea from "../reusable/CustomTextArea";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import { BsCurrencyRupee } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import ManualButton from "../reusable/ManualButton";
import {
  courseClearErrors,
  courseClearState,
  getCourseDetails,
  updateCourse,
} from "../../redux/action/courseAction";
import ButtonLoader from "../reusable/ButtonLoader";
import Loader from "../reusable/Loader";
import {
  categoryClearErrors,
  getAllCategoryList,
} from "../../redux/action/categoryAction";
const UpdateCourse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // GET COURSE DETAILS BY ID
  const { id: courseId } = useParams();
  const { loading, courseDetails, error } = useSelector(
    (state) => state.courseDetail
  );
  const getCourseById = courseDetails?.course;
  useEffect(() => {
    dispatch(getCourseDetails(courseId));
  }, [courseId, dispatch]);

  useEffect(() => {
    if (error) {
      alert(error);
      dispatch(courseClearErrors());
    }
  }, [error, dispatch]);

  const [formData, setFormData] = useState({
    title: "",
    sessionDuration: "",
    price: "",
    discountPercentage: "",
    discountedPrice: "",
    description: "",
    examFees: "",
  });

  const [category, setCategory] = useState();
  const [feature, setFeature] = useState("");
  const [featureList, setFeatureList] = useState(
    getCourseById?.features ? getCourseById?.features : []
  );
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState(
    getCourseById?.subjectList ? getCourseById?.subjectList : []
  );
  const [image, setImage] = useState("");

  useEffect(() => {
    if (getCourseById) {
      setFormData({
        title: getCourseById?.title || "",
        sessionDuration: getCourseById?.sessionDuration || "",
        price: getCourseById?.price || "",
        discountPercentage: getCourseById?.discountPercentage || "",
        discountedPrice:
          getCourseById?.discountedPrice !== 0
            ? getCourseById?.discountedPrice
            : "",
        description: getCourseById?.description || "",
        examFees: getCourseById?.examFees || "",
      });
    }
  }, [getCourseById]);

  useEffect(() => {
    setFeatureList(getCourseById?.features);
    setSubjectList(getCourseById?.subjectList);
  }, [getCourseById?.features, getCourseById?.subjectList]);

  //   SELECT THUMNAIL FUNCTION
  const handleAvatarChange = (e) => {
    if (e.target.name === "image") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  //   DESTRUCTURE ALL DATA
  const {
    title,
    sessionDuration,
    price,
    description,
    discountPercentage,
    examFees,
  } = formData;

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      discountedPrice: discountPercentage
        ? price - (price * discountPercentage) / 100
        : "",
    }));
  }, [discountPercentage, price]);

  // DISCOUNT VALIDATION
  useEffect(() => {
    if (discountPercentage) {
      const intValue = parseInt(discountPercentage, 10);
      if (isNaN(intValue) || intValue < 0 || intValue > 100) {
        showNotification("error", "please enter a valid discountPercentage");
      }
    }
  }, [discountPercentage, showNotification]);

  // Price VALIDATION
  useEffect(() => {
    if (price) {
      const isValidInput = /^\d*$/.test(price);
      if (!isValidInput) {
        showNotification("error", "please enter a valid price value");
      }
    }
  }, [price, showNotification]);

  // HANDLE FEATURE ADDS START
  const handleAddFeature = () => {
    if (!feature) {
      showNotification("error", "Please add a feature");
    } else if (featureList?.length >= 6) {
      showNotification("error", "You can add maximum 6 feature");
    } else {
      setFeatureList([...featureList, feature]);
      setFeature("");
    }
  };

  // HANDLE REMOVE FEATURE
  const handleRemoveFeature = (index) => {
    const updatedFeatureList = featureList.filter((_, i) => i !== index);
    // Update the state with the new array
    setFeatureList(updatedFeatureList);
  };

  //  HANDLE subject ADDS START
  const handleAddSubject = () => {
    if (!subject) {
      showNotification("error", "Please add a subject");
    } else if (subjectList?.length >= 6) {
      showNotification("error", "You can add maximum 6 subject");
    } else {
      setSubjectList([...subjectList, subject]);
      setSubject("");
    }
  };

  // HANDLE REMOVE subject
  const handleRemoveSubject = (index) => {
    const updatedsubjectList = subjectList.filter((_, i) => i !== index);
    // Update the state with the new array
    setSubjectList(updatedsubjectList);
  };

  //   CREATE COURSE ACTION
  const handleSubmit = (e) => {
    const thumbnail = image;
    const parsePrice = parseInt(price);
    dispatch(
      updateCourse(
        courseId,
        title,
        parsePrice,
        discountPercentage,
        description,
        category,
        sessionDuration,
        featureList,
        subjectList,
        thumbnail,
        examFees
      )
    );
  };

  const {
    courseData,
    error: updateError,
    loading: updateLoading,
  } = useSelector((state) => state.courseUpdate);

  useEffect(() => {
    if (updateError) {
      showNotification("error", updateError);
      dispatch(courseClearErrors());
    }
    if (courseData?.success) {
      showNotification("success", courseData?.message);
      navigate("/admin/courses");
      dispatch(courseClearState());
    }
  }, [
    updateError,
    courseData?.success,
    courseData?.message,
    showNotification,
    navigate,
    dispatch,
  ]);

  // GET CATEGORY LIST
  const {
    loading: categoryLoading,
    error: categoryError,
    categoryList: data,
  } = useSelector((state) => state.getAllCategory);

  useEffect(() => {
    dispatch(getAllCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (categoryError) {
      showNotification("error", categoryError);
      dispatch(categoryClearErrors());
    }
  }, [dispatch, categoryError, showNotification]);

  // GET CATEGORY LIST ENDS

  let categoryOption = [];

  for (let i = 0; i < data?.category?.length; i++) {
    let optionData = {
      value: data?.category[i]?._id,
      label: data?.category[i]?.category,
    };
    categoryOption.push(optionData);
  }
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="create_course">
              <div className="title">
                <button
                  className="create_btn"
                  onClick={() => navigate("/admin/courses")}
                >
                  <IoMdArrowRoundBack />
                </button>
                <h2>Update Course</h2>
              </div>
              <div className="course_form">
                <CustomInput
                  label="Course Title"
                  impStar="*"
                  placeholder="Course Title"
                  type="text"
                  onChange={handleInput}
                  value={formData.title}
                  name="title"
                  icon=""
                />
                <CustomInput
                  label="Course Duration (In months)"
                  placeholder={"Course Duration"}
                  type={"number"}
                  impStar="*"
                  onChange={handleInput}
                  value={formData.sessionDuration}
                  name={"sessionDuration"}
                  icon={""}
                />
                <CustomInput
                  label="Course Price"
                  impStar="*"
                  placeholder="Course Price"
                  type="number"
                  onChange={handleInput}
                  value={formData.price}
                  name="price"
                  icon=""
                />
                <div className="discount_tab">
                  <CustomInput
                    label="Discount Percentage"
                    placeholder="Discount per."
                    type="number"
                    onChange={handleInput}
                    value={formData.discountPercentage}
                    name="discountPercentage"
                    icon=""
                  />
                  <div className="discounted_price">
                    <span>Discounted Price</span>
                    <p>
                      {formData?.discountedPrice !== "" && (
                        <BsCurrencyRupee
                          style={{ fontSize: "14px", marginRight: "10px" }}
                        />
                      )}
                      {formData?.discountedPrice !== "" ||
                      getCourseById?.discountedPrice !== ""
                        ? formData?.discountedPrice ||
                          getCourseById?.discountedPrice
                        : "No discount"}
                    </p>
                  </div>
                </div>
                <CustomInput
                  label="Exam Fees"
                  impStar="*"
                  placeholder="Exam Fees"
                  type="number"
                  onChange={handleInput}
                  value={formData.examFees}
                  name="examFees"
                  icon=""
                />

                <CustomSelect
                  label="Course Type"
                  impStar="*"
                  isMulti={false}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  optionArray={categoryOption}
                  loading={categoryLoading}
                />
                <CustomInput
                  id="uploadProfilePic"
                  label="Upload Course Image"
                  type="file"
                  impStar="*"
                  name="image"
                  accept="image/*"
                  onChange={handleAvatarChange}
                />
                <CustomTextarea
                  label="Course Description"
                  type="text"
                  onChange={handleInput}
                  placeholder="Upto 500 words..."
                  name="description"
                  value={formData.description}
                  fullWidth
                />

                <div className="sc-irTswW izZXSN myInput">
                  <label>
                    Course Features (Add minimum 4 feature){" "}
                    <abbr className="impStar">*</abbr>
                  </label>
                  <div className="input_icon courseFeatures">
                    <input
                      className="input_text"
                      type="text"
                      placeholder="Course features..."
                      name="feature"
                      value={feature}
                      onChange={(e) => setFeature(e.target.value)}
                    />
                    <button title="Add More Feature" onClick={handleAddFeature}>
                      <FaPlus />
                    </button>
                  </div>
                  {featureList && featureList?.length > 0 && (
                    <div className="feature_list">
                      <ul>
                        {featureList.map((ele, i) => (
                          <li key={i}>
                            <div>
                              <p>{ele}</p>
                              <span onClick={() => handleRemoveFeature(i)}>
                                <RxCross2 />
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="sc-irTswW izZXSN myInput">
                  <label>
                    Course Subject (Add minimum 4 subject){" "}
                    <abbr className="impStar">*</abbr>
                  </label>
                  <div className="input_icon courseFeatures">
                    <input
                      className="input_text"
                      type="text"
                      placeholder="Course subjects..."
                      name="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <button title="Add More subject" onClick={handleAddSubject}>
                      <FaPlus />
                    </button>
                  </div>
                  {subjectList && subjectList?.length > 0 && (
                    <div className="feature_list">
                      <ul>
                        {subjectList.map((ele, i) => (
                          <li key={i}>
                            <div>
                              <p>{ele}</p>
                              <span onClick={() => handleRemoveSubject(i)}>
                                <RxCross2 />
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="submit_form">
                <ManualButton
                  className={`create ${updateLoading ? "disabled" : ""}`}
                  title={updateLoading ? <ButtonLoader /> : "Update"}
                  onClick={handleSubmit}
                  width={"110px"}
                />
              </div>
            </div>
          </ProfileLayout>
        </Layout>
      )}
    </Fragment>
  );
};

export default UpdateCourse;
