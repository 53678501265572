export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";

export const ADMIN_LOGOUT_REQUEST = "ADMIN_LOGOUT_REQUEST";
export const ADMIN_LOGOUT_SUCCESS = "ADMIN_LOGOUT_SUCCESS";
export const ADMIN_LOGOUT_FAIL = "ADMIN_LOGOUT_FAIL";

export const ADMIN_UPDATE_REQUEST = "ADMIN_UPDATE_REQUEST";
export const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";
export const ADMIN_UPDATE_FAIL = "ADMIN_UPDATE_FAIL";
export const CLEAR_UPDATE_STATE = "CLEAR_UPDATE_STATE";

export const ADMIN_PROFILE_REQUEST = "ADMIN_PROFILE_REQUEST";
export const ADMIN_PROFILE_SUCCESS = "ADMIN_PROFILE_SUCCESS";
export const ADMIN_PROFILE_FAIL = "ADMIN_PROFILE_FAIL";

export const UPDATE_PROFILE_PIC_REQUEST = "UPDATE_PROFILE_PIC_REQUEST";
export const UPDATE_PROFILE_PIC_SUCCESS = "UPDATE_PROFILE_PIC_SUCCESS";
export const UPDATE_PROFILE_PIC_FAIL = "UPDATE_PROFILE_PIC_FAIL";

export const UPDATE_AVATAR_REQUEST = "UPDATE_AVATAR_REQUEST";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_AVATAR_FAIL = "UPDATE_AVATAR_FAIL";

export const REMOVE_AVATAR_REQUEST = "REMOVE_AVATAR_REQUEST";
export const REMOVE_AVATAR_SUCCESS = "REMOVE_AVATAR_SUCCESS";
export const REMOVE_AVATAR_FAIL = "REMOVE_AVATAR_FAIL";

export const CLEAR_PROFILE_ERROR = "CLEAR_PROFILE_ERROR";
export const ADMIN_CLEAR_ERROR = "ADMIN_CLEAR_ERROR";
export const ADMIN_CLEAR_STATE = "ADMIN_CLEAR_STATE";
