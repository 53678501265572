import React from "react";
import styled from "styled-components";

const MyDiv = styled.div`
  .optionClass {
    color: #f2f2f2;
    background: #121d40;
    font-size: 120%;

    &:checked {
      background: #f2f2f2 !important;
      color: #121d40 !important;
    }
  }

  margin-bottom: 25px;
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  > label {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #003a66;
    > .impStar {
      color: red;
      font-size: 18px;
    }
    @media screen and (max-width: 575px) {
      font-size: 13px;
    }
  }
  > select {
    display: block;
    width: 100%;
    height: 50px;
    border: 1px solid #b9b9b9;
    border-radius: 40px;
    outline: none;
    box-sizing: border-box;
    padding: 10px;
    color: #333;
    background-color: #f2f2f2;
    font-size: 14px;
    font-family: nunito;
    @media screen and (max-width: 575px) {
      height: 40px;
    }
  }
`;

const CustomSelect = ({
  label,
  name,
  onChange,
  optionArray,
  impStar,
  value,
  readOnly,
  placeholder,
  classStyle,
  borderColor,
}) => {
  return (
    <MyDiv className="myInput">
      <label>
        {label} {impStar && <abbr className="impStar">{impStar}</abbr>}
      </label>
      <select
        className={classStyle}
        name={name}
        id=""
        readOnly={readOnly}
        disabled={readOnly ? true : false}
        value={value}
        onChange={onChange}
        style={{ border: borderColor && `1px solid ${borderColor}` }}
      >
        <option className="optionClass" value="">
          {placeholder ? placeholder : "Select One Option"}
        </option>
        {optionArray &&
          optionArray?.map((item, idx) => (
            <option className="optionClass" key={idx} value={item?.value ? item?.value : item?.label}>
              {item.label}
            </option>
          ))}
      </select>
    </MyDiv>
  );
};

export default CustomSelect;
