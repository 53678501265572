import { axiosInstance } from "../../utils/axiosInstance";
import { CLEAR_UPDATE_STATE } from "../constant/adminConstant";
import {
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  // CLEAR_UPDATE_STATE,
  GET_ALL_USERS_FAIL,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_REQUEST,
  GET_USER_BY_ID_SUCCESS,
  UPDATE_USER_BY_ID_FAIL,
  UPDATE_USER_BY_ID_REQUEST,
  UPDATE_USER_BY_ID_SUCCESS,
  USER_CLEAR_ERROR,
  USER_CLEAR_STATE,
  USER_REGISER_FAIL,
  USER_REGISER_REQUEST,
  USER_REGISER_SUCCESS,
} from "../constant/userConstant";

// FOR USER REGISTRATION
export const userRegisterAction =
  (
    fullName,
    email,
    phone,
    state,
    city,
    village,
    pinCode,
    fatherName,
    dob,
    password,
    avatar,
    gender,
    role,
    courseId,
    batch
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: USER_REGISER_REQUEST,
      });

      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };

      const { data } = await axiosInstance.post(
        "/admin/register",
        {
          fullName,
          email,
          phone,
          state,
          city,
          village,
          pinCode,
          fatherName,
          dob,
          password,
          avatar,
          gender,
          role,
          courseId,
          batch
        },
        config
      );
      dispatch({
        type: USER_REGISER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: USER_REGISER_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// GET ALL USERS LIST
export const getAllUsers = (batchName, courseName) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_USERS_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    let url = "/admin/users";

    if (batchName && courseName) {
      url += `?batch=${batchName}&courseId=${courseName}`;
    }

    const { data } = await axiosInstance.get(url, config);

    dispatch({
      type: GET_ALL_USERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_USERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// GET USER DETAILS BY ID
export const getUserById = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_BY_ID_REQUEST,
    });

    const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${sessionStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.get(`/profile`, config);

    dispatch({
      type: GET_USER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_BY_ID_FAIL,
      payload: error.response.data.message,
    });
  }
};

// UPDATE USER PROFILE
export const updateUserById =
  (
    fullName,
    email,
    phone,
    state,
    city,
    village,
    pinCode,
    fatherName,
    dob,
    avatar,
    gender,
    role,
    isVerified
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_USER_BY_ID_REQUEST,
      });
      const sessionStorageToken = sessionStorage.getItem("niotamAdminToken")
        ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
        : "";

      const config = {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${sessionStorageToken?.token}`,
        },
      };
      const { data } = await axiosInstance.put(
        `/update-profile`,
        {
          fullName,
          email,
          phone,
          state,
          city,
          village,
          pinCode,
          fatherName,
          dob,
          avatar,
          gender,
          role,
          isVerified
        },
        config
      );
      dispatch({
        type: UPDATE_USER_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_BY_ID_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// DELETE USER BY ID
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_REQUEST,
    });

    const localStorageToken = sessionStorage.getItem("niotamAdminToken")
      ? JSON.parse(sessionStorage.getItem("niotamAdminToken"))
      : "";

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${localStorageToken?.token}`,
      },
    };

    const { data } = await axiosInstance.delete(`/user/${id}`, config);

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// CLEAR ERROR ACTION FUNCTION
export const clearUserErrors = () => async (dispatch) => {
  dispatch({ type: USER_CLEAR_ERROR });
};

// CLEAR ERROR ACTION FUNCTION
export const clearUserState = () => async (dispatch) => {
  dispatch({ type: USER_CLEAR_STATE });
};

// CLEAR STATE ACTION FUNCTION
export const clearUpdateState = () => async (dispatch) => {
  dispatch({ type: CLEAR_UPDATE_STATE });
};
