import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import NoDataCard from "../reusable/NoDataCard";
import { FaPlus, FaRegSadTear, FaSearch } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../styles/courseList.scss";
import Loader from "../reusable/Loader";
import moment from "moment/moment";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  TableSortLabel,
  InputBase,
  Switch,
} from "@material-ui/core";
import {
  clearMarkSheetDownloadState,
  clearMarksheetDownloadErrors,
  clearMarksheetUpdateState,
  getAllMarkSheet,
  updateMarksheet,
  viewMarkSheet,
} from "../../redux/action/markSheetAction";
import ButtonLoader from "../reusable/ButtonLoader";

const OutMarksheet = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();

  // PAGINATION STATES
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  // PAGINATION FUNCTION ENDS

  const { loading, error, markSheetList } = useSelector(
    (state) => state.getAllMarksheet
  );
  const data = markSheetList?.marksheet;

  useEffect(() => {
    dispatch(getAllMarkSheet());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearMarksheetDownloadErrors());
    }
  }, [dispatch, error, showNotification]);

  //   VIEW RESULT

  const {
    loading: certificateLoading,
    certificateInfo,
    error: certificateError,
  } = useSelector((state) => state.findMarksheet);
  const handleViewDetails = (ele) => {
    const roll_no = ele?.student?._id;
    const course_id = ele?.course?._id;
    if (roll_no && course_id) {
      dispatch(viewMarkSheet(roll_no, course_id));
    } else {
      showNotification("error", "Please fill all the required fields.");
    }
  };

  useEffect(() => {
    if (certificateError) {
      showNotification("error", certificateError);
      dispatch(clearMarksheetDownloadErrors());
    }

    if (typeof certificateInfo == "string") {
      dispatch(clearMarkSheetDownloadState());
      navigate("/admin/view-marksheet/certificate", { state: certificateInfo });
    }
  }, [
    dispatch,
    showNotification,
    error,
    certificateInfo,
    certificateError,
    navigate,
  ]);

  const createMarksheet = () => {
    navigate("/admin/create-marksheet");
  };
  const [verify, setVerify] = useState(null);

  const handleSwitchChange = (ele) => {
    const isVerify = !ele?.isVerified;
    setVerify(ele?._id);
    dispatch(updateMarksheet(ele?.student?._id, ele?.course?._id, isVerify));
  };
  const {
    loading: updateLoading,
    error: updateError,
    marksheetInfo,
  } = useSelector((state) => state.updateMarksheet);

  useEffect(() => {
    if (updateError) {
      showNotification("error", updateError);
      dispatch(clearMarksheetDownloadErrors());
    }
    if (marksheetInfo?.success) {
      showNotification("success", marksheetInfo?.message);

      dispatch(clearMarksheetUpdateState());
      dispatch(getAllMarkSheet());
    }
  }, [
    dispatch,
    updateError,
    marksheetInfo?.success,
    marksheetInfo?.message,
    showNotification,
  ]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="course_list">
              <div className="title">
                <h2>Courses</h2>
                <button
                  title="Create Marksheet"
                  className="create_btn"
                  onClick={() => createMarksheet()}
                >
                  <FaPlus />
                </button>
              </div>
              {data?.length > 0 ? (
                <div className="timing_calendar">
                  <div className="search_box">
                    <InputBase
                      placeholder="Search…"
                      value={searchTerm}
                      onChange={handleSearch}
                      startAdornment={<FaSearch />}
                      className="search_input"
                    />
                  </div>
                  <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <TableSortLabel>
                              <b>Certificate No</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>
                              <b>Student Name</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>
                              <b>Student Roll No</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <TableSortLabel>
                              <b>Course</b>
                            </TableSortLabel>
                          </TableCell>

                          <TableCell>
                            <TableSortLabel>
                              <b>Session</b>
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>
                            <b>Created Date & Time</b>
                          </TableCell>
                          <TableCell>
                            <b>Status</b>
                          </TableCell>
                          <TableCell>
                            <b>Action</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data
                          ?.filter(
                            (course) =>
                              course.certificateNo
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              course?.student?.rollNo
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase()) ||
                              course?.student?.fullName
                                .toLowerCase()
                                .includes(searchTerm.toLowerCase())
                          )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((ele, idx) => (
                            <TableRow key={ele._id}>
                              <TableCell>{ele?.certificateNo}</TableCell>
                              <TableCell>{ele?.student?.fullName}</TableCell>
                              <TableCell>{ele?.student?.rollNo}</TableCell>
                              <TableCell>{ele?.course?.title}</TableCell>
                              <TableCell>{ele?.session}</TableCell>
                              <TableCell>
                                {moment(ele?.createdAt).format(
                                  "MMMM-DD-YYYY [at] h:mm A"
                                )}
                              </TableCell>
                              <TableCell className="txt_center">
                                <span
                                  className={`${
                                    ele?.isVerified ? "green" : "red"
                                  }`}
                                >
                                  {ele?.isVerified ? "Verified" : "Un-verified"}
                                </span>
                              </TableCell>
                              <TableCell>
                                <div className="button_container">
                                  <Button
                                    className="edit_btn"
                                    onClick={() => handleViewDetails(ele)}
                                  >
                                    {certificateLoading ? (
                                      <ButtonLoader />
                                    ) : (
                                      <AiFillEye />
                                    )}
                                  </Button>
                                  {updateLoading && verify === ele?._id ? (
                                    <Button className="edit_btn">
                                      <ButtonLoader />
                                    </Button>
                                  ) : (
                                    <Switch
                                      checked={ele?.isVerified}
                                      onChange={() => handleSwitchChange(ele)}
                                      color="primary"
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              ) : (
                <NoDataCard
                  txt="There is no course added yet"
                  icon={<FaRegSadTear />}
                />
              )}
            </div>
          </ProfileLayout>
        </Layout>
      )}
    </Fragment>
  );
};

export default OutMarksheet;
