import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import { IoMdArrowRoundBack } from "react-icons/io";
import "../../styles/CategoryCreate.scss";
import CustomInput from "../reusable/CustomInput";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
// import { BsCurrencyRupee } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import ManualButton from "../reusable/ManualButton";
import ButtonLoader from "../reusable/ButtonLoader";
import {
  categoryClearErrors,
  categoryClearState,
  getCategoryDetails,
  updateCategory,
} from "../../redux/action/categoryAction";
import Loader from "../reusable/Loader";
const CategoryEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    category: "",
  });
  const [subjects, setSubjects] = useState();
  const [subjectList, setSubjectList] = useState([]);

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  //   DESTRUCTURE ALL DATA
  const { category } = formData;

  // GET CATEGORY DETAILS
  const { loading, error, categoryDetails } = useSelector(
    (state) => state.getCategoryDetails
  );

  useEffect(() => {
    dispatch(getCategoryDetails(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (error) {
      dispatch(categoryClearErrors());
    }
    if (categoryDetails) {
      setFormData({
        category: categoryDetails?.category?.category,
      });
      setSubjectList(categoryDetails?.category?.subjects);
    }
  }, [error, dispatch, categoryDetails]);

  // HANDLE FEATURE ADDS START
  const handleAddCategory = () => {
    if (!subjects) {
      showNotification("error", "Please add a subjects");
    } else if (subjectList?.length >= 6) {
      showNotification("error", "You can add maximum 6 subjects");
    } else {
      setSubjectList([...subjectList, subjects]);
      setSubjects("");
    }
  };
  // HANDLE REMOVE FEATURE
  const handleRemoveFeature = (index) => {
    const updatedFeatureList = subjectList.filter((_, i) => i !== index);
    setSubjectList(updatedFeatureList);
  };

  //   CREATE COURSE ACTION
  const handleSubmit = () => {
    dispatch(updateCategory(id,category, subjectList));
  };

  const {
    categoryData,
    error: updateError,
    loading: updateLoading,
  } = useSelector((state) => state.categoryUpdate);

  useEffect(() => {
    if (updateError) {
      showNotification("error", updateError);
      dispatch(categoryClearErrors());
    }
    if (categoryData?.success) {
      showNotification("success", categoryData?.message);
      navigate("/admin/category");
      dispatch(categoryClearState());
    }
  }, [
    updateError,
    categoryData?.success,
    categoryData?.message,
    showNotification,
    navigate,
    dispatch,
  ]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Layout>
          <ProfileLayout>
            <div className="create_category">
              <div className="title">
                <button
                  className="create_btn"
                  onClick={() => navigate("/admin/category")}
                >
                  <IoMdArrowRoundBack />
                </button>
                <h2>Edit Category</h2>
              </div>
              <div className="course_form">
                <CustomInput
                  label="Category Name"
                  impStar="*"
                  placeholder="Category Title"
                  type={"text"}
                  onChange={handleInput}
                  value={formData.category}
                  name={"category"}
                  icon={""}
                />

                <div className="sc-irTswW izZXSN myInput">
                  <label>
                    Category Subjects
                    <abbr className="impStar">*</abbr>
                  </label>
                  <div className="input_icon courseFeatures">
                    <input
                      className="input_text"
                      type="text"
                      placeholder="etc..."
                      name="subjects"
                      value={subjects}
                      onChange={(e) => setSubjects(e.target.value)}
                    />
                    <button
                      title="Add More Subjects"
                      onClick={handleAddCategory}
                    >
                      <FaPlus />
                    </button>
                  </div>
                  {subjectList && subjectList?.length > 0 && (
                    <div className="feature_list">
                      <ul>
                        {subjectList.map((ele, i) => (
                          <li key={i}>
                            <div>
                              <p>{ele}</p>
                              <span onClick={() => handleRemoveFeature(i)}>
                                <RxCross2 />
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="submit_form">
                <ManualButton
                  className="create"
                  title={updateLoading ? <ButtonLoader /> : "Update"}
                  onClick={handleSubmit}
                  width={"110px"}
                />
              </div>
            </div>
          </ProfileLayout>
        </Layout>
      )}
    </Fragment>
  );
};

export default CategoryEdit;
