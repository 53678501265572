import React from "react";
import styled from "styled-components";
import { RxCross2 } from "react-icons/rx";
const DetailsWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  // background-color: rgba(8, 19, 54, 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  > .bg_color {
    background-color: #b7b7b7;
    position: absolute;
    min-height: 100vh;
    min-width: 100vw;
    opacity: 0.4;
    z-index: -1;
  }
`;
const DetailsCard = styled.div`
  min-width: 360px;
  background-color: #02298a;
  padding: 10px;
  border-radius: 12px;
  color: #f2f2f2;
  z-index: 999;
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    > h2 {
      font-size: 20px;
      text-transform: capitalize;
    }
    > button {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
      color: #f2f2f2;
      font-size: 18px;
      border-radius: 4px;
    }
  }
  > .info {
    text-align: center;
    color: #f2f2f2;
    padding: 10px;
    font-size: 14px;
    > p {
      margin-bottom: 15px;
    }
  }
`;
const DetailsPopup = ({ title, onClick, details, transactionDetails }) => {
  
  return (
    <DetailsWrapper>
      <div className="bg_color"></div>
      <DetailsCard>
        <div className="header">
          <h2 className="heading_top">{title}</h2>
          <button onClick={onClick}>
            <RxCross2 />
          </button>
        </div>
        {details && (
          <div className="info">
            <p>FullName : {details?.fullName}</p>
            <p>Email : {details?.email}</p>
            <p>Mobile : {details?.phone}</p>
            <p>Register No : {details?.rollNo}</p>
            {details?.dob !== "" && <p>DOB : {details?.dob}</p>}
            {details?.fatherName !== "" && (
              <p>Father Name : {details?.fatherName}</p>
            )}
            <p>State : {details?.state}</p>
            <p>City : {details?.city}</p>
            <p>Village : {details?.village}</p>
            <p>Pincode : {details?.pinCode}</p>
          </div>
        )}
        {transactionDetails && (
          <div className="info">
            <p>User Name : {transactionDetails?.user?.fullName}</p>
            <p>Email : {transactionDetails?.user?.email}</p>
            <p>Mobile : {transactionDetails?.user?.phone}</p>
            <p>State : {transactionDetails?.user?.state}</p>
            <p>City : {transactionDetails?.user?.city}</p>
            <p>Village : {transactionDetails?.user?.village}</p>
            <p>Pincode : {transactionDetails?.user?.pinCode}</p>
            <p>Paid Method : {transactionDetails?.paidBy}</p>
          </div>
        )}
      </DetailsCard>
    </DetailsWrapper>
  );
};

export default DetailsPopup;
