import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { logout } from "../../redux/action/adminAction";

const RedirectLoader = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2f2f2;
  background: #000;

  span {
    color: red;
    font-weight: 700;
  }

  h3 {
    color: #f2f2f2;
  }

  a {
    display: block;
    margin: 20px auto;
    border: 1px solid #f2f2f2;
    width: fit-content;
    color: #f2f2f2;
    padding: 10px 20px;
    border-radius: 100px;
    transition: 0.4s;
    background: transparent;

    &:hover {
      background: #f2f2f2;
      color: #081336;
    }
  }
`;

const RedirectSpinner = ({ path = "/" }) => {
  const [count, setCount] = useState(5);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => --prevCount);
    }, 1000);
    count === 0 &&
      navigate(
        `${path}`
      );
    if (count === 0) {
      dispatch(logout());
    }
    return () => clearInterval(interval);
  }, [count, navigate, location, path, dispatch]);

  return (
    <RedirectLoader className="txt_color">
      <div className="container">
        <h3 className="monserrat_font">
          You're not authenticated Please login before use this resource.
          Redirecting to you in <span>{count}</span> second.
        </h3>
        <Link to="/">Login</Link>
      </div>
    </RedirectLoader>
  );
};

export default RedirectSpinner;
