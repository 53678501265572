import React, { Fragment, useEffect, useState } from "react";
import ManualButton from "../reusable/ManualButton";
import ButtonLoader from "../reusable/ButtonLoader";
import { IoMdArrowRoundBack } from "react-icons/io";
import ProfileLayout from "../layouts/SidebarLayout";
import Layout from "../layouts/Layout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import CustomInput from "../reusable/CustomInput";
import CustomSelect from "../reusable/CustomSelect";
import "../../styles/createUser.scss";
import {
  clearUserErrors,
  clearUserState,
  userRegisterAction,
} from "../../redux/action/userAction";
import { userImg } from "../../assest/images";
import { indianStates } from "../../arrayFiles/stateList";

const CreateFranchise = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    city: "",
    pinCode: "",
    village: "",
    fatherName: "",
    dob: "",
    password: "",
    confirmPassword: "",
  });
  const [role] = useState(12);
  const [gender, setGender] = useState();
  const [state, setState] = useState();

  const [image, setImage] = useState();
  const [avatarPreview, setAvatarPreview] = useState(userImg);

  const handleInputChange = (e) => {
    // setImage(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setImage(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // GET REGISTER USER STATE FROM STORE
  const { error, userInfo, loading } = useSelector((state) => state.register);

  // DESTRUCTURE ALL FORM VALUE
  const {
    fullName,
    email,
    phone,
    city,
    village,
    pinCode,
    fatherName,
    dob,
    password,
    confirmPassword,
    
  } = formData;

  // CREATE USER FUNCTION
  const handleSubmit = () => {
    if (password.length < 8) {
      showNotification("error", "Password must have 8 characters");
    } else if (
      fullName === "" &&
      email === "" &&
      phone === "" &&
      state === "" &&
      city === "" &&
      village === "" &&
      pinCode === "" &&
      gender === "" &&
      image === ""
    ) {
      showNotification("error", "Please fill all fields carefully");
    } else if (password !== confirmPassword) {
      showNotification("error", "Password and confirm password must be same!");
    } else {
      const roleValue = parseInt(role);
      dispatch(
        userRegisterAction(
          fullName,
          email,
          phone,
          state,
          city,
          village,
          pinCode,
          fatherName,
          dob,
          password,
          image,
          gender,
          roleValue,
          ""
        )
      );
    }
  };

  // HANDLE ERROR AFTER USER CREATION
  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(clearUserErrors());
    }
    if (userInfo?.success) {
      showNotification("success", userInfo?.message);
      dispatch(clearUserState());
      navigate("/admin/franchise");
    }
  }, [
    error,
    dispatch,
    userInfo?.message,
    userInfo?.success,
    showNotification,
    navigate,
  ]);
  return (
    <Fragment>
      <Layout>
        <ProfileLayout>
          <div className="create_user">
            <div className="title">
              <button
                className="create_btn"
                onClick={() => navigate("/admin/franchise")}
              >
                <IoMdArrowRoundBack />
              </button>
              <h2>Franchise Register</h2>
            </div>
            <div className="course_form">
              <CustomInput
                label="Owner Name"
                impStar="*"
                placeholder="Owner Name"
                type="text"
                onChange={handleInput}
                value={formData.fullName}
                name="fullName"
                icon=""
              />
              <CustomInput
                label="Owner Email"
                placeholder={"Owner Email"}
                type="email"
                impStar="*"
                onChange={handleInput}
                value={formData.email}
                name={"email"}
                icon=""
              />
              <CustomInput
                label="Owner Mobile No."
                impStar="*"
                placeholder="Owner Mobile No."
                type="number"
                onChange={handleInput}
                value={formData.phone}
                name="phone"
                icon=""
              />
              <CustomSelect
                placeholder="State"
                isMulti={false}
                value={state}
                label="Select State"
                className="myInput"
                onChange={(e) => setState(e.target.value)}
                optionArray={indianStates}
                icon=""
              />
              <CustomInput
                label="City"
                impStar="*"
                placeholder="City"
                type="text"
                onChange={handleInput}
                value={formData.city}
                name="city"
                icon=""
              />
              <CustomInput
                label="Village"
                impStar="*"
                placeholder="Village"
                type="text"
                onChange={handleInput}
                value={formData.village}
                name="village"
                icon=""
              />
              <CustomInput
                label="Pincode"
                impStar="*"
                placeholder="Pincode"
                type="number"
                onChange={handleInput}
                value={formData.pinCode}
                name="pinCode"
                icon=""
              />

              <CustomSelect
                label="Gender"
                impStar="*"
                isMulti={false}
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                optionArray={[
                  {
                    value: "male",
                    label: "Male",
                  },
                  {
                    value: "female",
                    label: "Female",
                  },
                  {
                    value: "other",
                    label: "Others",
                  },
                ]}
              />
              <CustomInput
                label="Account Password"
                impStar="*"
                placeholder="Account Password"
                type="password"
                onChange={handleInput}
                value={formData.password}
                name="password"
                icon=""
              />
              <CustomInput
                label="Confirm Account Password"
                impStar="*"
                placeholder="Confirm Account Password"
                type="password"
                onChange={handleInput}
                value={formData.confirmPassword}
                name="confirmPassword"
                icon=""
              />

              <CustomInput
                label="Profile Image"
                impStar="*"
                placeholder=""
                type="file"
                name="image"
                accept="image/*"
                onChange={handleInputChange}
              />
            </div>
            <div className="image_preview">
              <img src={avatarPreview} alt="user_img" />
            </div>
            <div className="submit_form">
              <ManualButton
                className="create"
                title={loading ? <ButtonLoader /> : "Create"}
                onClick={handleSubmit}
                width={"110px"}
              />
            </div>
          </div>
        </ProfileLayout>
      </Layout>
    </Fragment>
  );
};

export default CreateFranchise;
