import React, { Fragment, useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import CustomInput from "../reusable/CustomInput";
import { useNotification } from "../../context/notificationContext";
import { useDispatch, useSelector } from "react-redux";
import ManualButton from "../reusable/ManualButton";
import ButtonLoader from "../reusable/ButtonLoader";
import CustomSelect from "../reusable/CustomSelect";
import {
  EXAMClearErrors,
  EXAMClearState,
  createExam,
} from "../../redux/action/examAction";
import {
  batchClearErrors,
  getAllBatchist,
} from "../../redux/action/batchAction";
import {
  courseClearErrors,
  getAllCourses,
} from "../../redux/action/courseAction";
import { clearUserErrors, getAllUsers } from "../../redux/action/userAction";
import { FaPlus } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import moment from "moment";

const CreateExam = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  const [formData, setFormData] = useState({
    title: "",
    date: "",
    time: "",
    location: "",
  });
  const [courseName, setCourseName] = useState();
  const [batchName, setbatchName] = useState();
  const [student, setStudent] = useState();
  const [studentList, setStudentList] = useState([]);
  //  HANLDE INPUT CHANGE ON ONcHANGE EVENT
  const handleInput = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // HANLDE GET ALL COURSES START
  const {
    // eslint-disable-next-line
    loading: courseLoading,
    error: courseError,
    courses: data,
  } = useSelector((state) => state.getCourses);

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  useEffect(() => {
    if (courseError) {
      showNotification("error", courseError);
      dispatch(courseClearErrors());
    }
  }, [dispatch, courseError, showNotification]);

  let courseOption = [];

  for (let i = 0; i < data?.courses?.length; i++) {
    let optionData = {
      value: data?.courses[i]?._id,
      label: data?.courses[i]?.title,
    };
    courseOption.push(optionData);
  }
  // HANLDE GET ALL COURSES ENDS

  // HANLDE GET ALL BATCHES
  const {
    loading: batchLoading,
    error: batchError,
    batchList,
  } = useSelector((state) => state.getAllBatch);

  useEffect(() => {
    dispatch(getAllBatchist());
  }, [dispatch]);

  useEffect(() => {
    if (batchError) {
      showNotification("error", batchError);
      dispatch(batchClearErrors());
    }
  }, [dispatch, batchError, showNotification]);

  let batchOption = [];

  for (let i = 0; i < batchList?.batches?.length; i++) {
    let optionData = {
      value: batchList?.batches[i]?._id,
      label: batchList?.batches[i]?.title,
    };
    batchOption.push(optionData);
  }

  // HANLDE GET ALL BATCHES END

  // HANDLE USER LIST
  const {
    loading: userLoading,
    error: userError,
    userInfo,
  } = useSelector((state) => state.getAllUser);

  useEffect(() => {
    if (courseName && batchName) {
      dispatch(getAllUsers(batchName, courseName));
    }
  }, [dispatch, batchName, courseName]);

  useEffect(() => {
    if (userError) {
      showNotification("error", userError);
      dispatch(clearUserErrors());
    }
  }, [dispatch, userError, showNotification]);

  let userOption = [];

  for (let i = 0; i < userInfo?.users?.length; i++) {
    let optionData = {
      value: userInfo?.users[i]?._id,
      label:
        userInfo?.users[i]?.fullName +
        " " +
        (userInfo?.users[i]?.gender === "male" ? "S/O" : "D/O") +
        `( ${userInfo?.users[i]?.fatherName})`,
    };
    userOption.push(optionData);
  }

  const handleAddStudent = (e) => {
    setStudentList([...studentList, student ]);
    setStudent("");
  };
  // HANDLE REMOVE FEATURE
  const handleRemoveStudent = (index) => {
    const updatedFeatureList = studentList.filter((_, i) => i !== index);
    // Update the state with the new array
    setStudentList(updatedFeatureList);
  };
  const { title, date, time, location } = formData;
  
  const handleSubmit = () => {
     const dateFormat = moment(date);
     const timeFormat = moment(time, "HH:mm");
    dispatch(
      createExam(
        title,
        dateFormat,
        timeFormat,
        location,
        courseName,
        batchName,
        studentList
      )
    );
  };

  const { error, loading, examData } = useSelector((state) => state.examCreate);

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(EXAMClearErrors());
    }
    if (examData?.success) {
      showNotification("success", examData?.message);
      dispatch(EXAMClearState());
      navigate("/admin/exams");
    }
  }, [
    error,
    dispatch,
    navigate,
    showNotification,
    examData?.success,
    examData?.message,
  ]);

  const mappedUsers = userInfo?.users
    ?.filter((user) => {
      return studentList?.some((student) => student === user._id);
    })
    .map((user) => ({
      fullName: user?.fullName,
      fatherName: user?.fatherName,
      gender: user?.gender,
    }));

  return (
    <Fragment>
      <Layout>
        <ProfileLayout>
          <div className="create_batch">
            <div className="title">
              <button
                className="create_btn"
                onClick={() => navigate("/admin/exams")}
              >
                <IoMdArrowRoundBack />
              </button>
              <h2>Create Exam</h2>
            </div>
            <div className="course_form">
              <CustomInput
                label="Exam Title"
                impStar="*"
                placeholder="Exam Title"
                type="text"
                onChange={handleInput}
                value={formData.title}
                name="title"
              />
              <CustomInput
                label="Exam Time"
                impStar="*"
                placeholder="Exam Time"
                type="time"
                onChange={handleInput}
                value={formData.time}
                name="time"
              />
              <CustomInput
                label="Exam Date"
                impStar="*"
                placeholder="Exam Date"
                type="date"
                onChange={handleInput}
                value={formData.date}
                name="date"
              />
              <CustomInput
                label="Center Location"
                impStar="*"
                placeholder="Enter complete location"
                type="text"
                onChange={handleInput}
                value={formData.location}
                name="location"
              />
              <CustomSelect
                placeholder="Select Course"
                isMulti={false}
                value={courseName}
                label="Select Course"
                className="myInput"
                onChange={(e) => setCourseName(e.target.value)}
                optionArray={courseOption}
                icon=""
              />
              <CustomSelect
                placeholder="Select Batch"
                isMulti={false}
                value={batchName}
                label="Select Batch"
                className="myInput"
                onChange={(e) => setbatchName(e.target.value)}
                optionArray={batchOption}
                loading={batchLoading}
                icon=""
              />
              {batchName && courseName && (
                <div className="sc-irTswW izZXSN myInput">
                  <label>
                    Choose Student For Hall Ticket
                    <abbr className="impStar">*</abbr>
                  </label>
                  <div className="input_icon courseFeatures">
                    <select
                      className="input_text"
                      name={student}
                      id=""
                      value={student}
                      loading={userLoading}
                      onChange={(e) => setStudent(e.target.value)}
                    >
                      <option className="optionClass" value="">
                        Select User
                      </option>
                      {userOption &&
                        userOption?.map((item, idx) => (
                          <option
                            className="optionClass"
                            key={idx}
                            value={item?.value}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                    <button title="Add More Feature" onClick={handleAddStudent}>
                      <FaPlus />
                    </button>
                  </div>
                  {studentList && studentList?.length > 0 && (
                    <div className="feature_list">
                      <ul>
                        {mappedUsers?.map((ele, i) => (
                          <li key={i}>
                            <div>
                              <p>
                                {ele?.fullName}{" "}
                                {ele?.gender === "male" ? "S/O" : "D/O"}{" "}
                                {ele?.fatherName}
                              </p>
                              <span onClick={() => handleRemoveStudent(i)}>
                                <RxCross2 />
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="submit_form">
              <ManualButton
                className="create"
                title={loading ? <ButtonLoader /> : "Create"}
                onClick={handleSubmit}
                width={"110px"}
              />
            </div>
          </div>
        </ProfileLayout>
      </Layout>
    </Fragment>
  );
};

export default CreateExam;
