import React, { Fragment, useEffect, useState } from "react";
import NoDataCard from "../reusable/NoDataCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  InputBase,
  Button,
} from "@material-ui/core";
import Layout from "../layouts/Layout";
import ProfileLayout from "../layouts/SidebarLayout";
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { FaDownload, FaRegSadTear, FaSearch } from "react-icons/fa";
// import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNotification } from "../../context/notificationContext";
import {
  EXAMClearErrors,
  printHallTicket,
} from "../../redux/action/examAction";
const ViewStudentList = () => {
  const dispatch = useDispatch();
  const [, , showNotification] = useNotification();
  // PAGINATION STATES
  const navigate = useNavigate();
  const location = useLocation();
  const studentData = location?.state;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");

  // PAGINATION FUNCTION START
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const { error, loading, printData } = useSelector(
    (state) => state.printHallTicket
  );

  const handlePrintTicket = (studentId) => {
    dispatch(printHallTicket(studentData?.course?._id, studentId));
  };

  useEffect(() => {
    if (error) {
      showNotification("error", error);
      dispatch(EXAMClearErrors());
    }
    if (typeof studentInfo == "string") {
      const blob = new Blob([printData], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "admitCard.html";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }, [printData, dispatch, error, showNotification]);

  // PAGINATION FUNCTION ENDS
  return (
    <Fragment>
      <Layout>
        <ProfileLayout>
          <div className="batch_list">
            <div className="title">
              <h2>Student Enroll In Exam</h2>
              <button className="create_btn" onClick={() => navigate(-1)}>
                <RxCross2 />
              </button>
            </div>
            {studentData?.students.length > 0 ? (
              <div className="timing_calendar">
                <div className="search_box">
                  <InputBase
                    placeholder="Search…"
                    value={searchTerm}
                    onChange={handleSearch}
                    startAdornment={<FaSearch />}
                    className="search_input"
                  />
                </div>
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <b>Student Name</b>
                        </TableCell>
                        <TableCell>
                          <b>Roll No</b>
                        </TableCell>
                        <TableCell>
                          <b>Email</b>
                        </TableCell>
                        <TableCell>
                          <b>Phone</b>
                        </TableCell>
                        <TableCell>
                          <b>DOB</b>
                        </TableCell>
                        <TableCell>
                          <b>Father Name</b>
                        </TableCell>
                        <TableCell>
                          <b>Action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentData?.students
                        ?.filter(
                          (course) =>
                            course.fullName
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            course.email
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((ele) => (
                          <TableRow key={ele._id}>
                            <TableCell>{ele?.fullName}</TableCell>

                            <TableCell>{ele?.rollNo}</TableCell>
                            <TableCell>{ele?.email}</TableCell>
                            <TableCell>{ele?.phone}</TableCell>
                            <TableCell>{ele?.dob}</TableCell>
                            <TableCell>{ele?.fatherName}</TableCell>
                            <TableCell className="txt_center">
                              <Button
                                title="Download Hall Ticket"
                                className="edit_btn"
                                onClick={() => handlePrintTicket(ele?._id)}
                              >
                                {loading ? "Downloading.." : <FaDownload />}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={studentData?.students.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            ) : (
              <NoDataCard
                txt="There is no student enroll in this exam"
                icon={<FaRegSadTear />}
              />
            )}
          </div>
        </ProfileLayout>
      </Layout>
    </Fragment>
  );
};

export default ViewStudentList;
